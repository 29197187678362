<template>
  <el-table :data="tableData" v-loading="tableLoading">
    <el-table-column label="序号" type="index" width="100"> </el-table-column>
    <el-table-column label="服务英文名">
      <template #default="scope">
        <service-name :name="scope.row.name" />
      </template>
    </el-table-column>
    <el-table-column label="服务中文名" prop="description"></el-table-column>
    <el-table-column label="服务详情" prop="detail"> </el-table-column>
    <el-table-column label="负责人" prop="owners"> </el-table-column>
    <el-table-column label="版本" prop="version"></el-table-column>
  </el-table>
</template>

<script>
import { ref, watch } from 'vue';
import { getDependencyList } from '@/api/servers';

export default {
  name: 'SubServiceList',
  props: ['serviceId'],
  setup(props) {
    const tableData = ref();
    const tableLoading = ref(true);
    const getTableData = async () => {
      try {
        tableLoading.value = true;
        const { data } = await getDependencyList({ id: props.serviceId, dependencyWay: 1 });
        tableData.value = data.rows.map((item) => ({
          name: item.service.name,
          description: item.service.description,
          detail: item.service.detail,
          owners: item.owners
            ?.sort((a, b) => a.id - b.id)
            .map((x) => x.displayName)
            .join(', '),
          version: item.dependencyServiceVersion,
        }));
        tableLoading.value = false;
      } catch (e) {
        console.log(e);
        tableLoading.value = false;
      }
    };
    getTableData();

    watch(
      () => props.serviceId,
      () => {
        getTableData();
      },
    );

    return {
      tableData,
      tableLoading,
    };
  },
};
</script>
