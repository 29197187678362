<template>
  <div class="env-detail__content">
    <div class="env-detail__content__head">
      <el-row style="margin-bottom: 10px">
        <el-col :offset="18" :span="6" style="text-align: right">
          <el-input
            style="max-width: 300px; margin-left: auto"
            placeholder="请输入部署名称"
            suffix-icon="el-icon-search"
            @input="handleSearch"
            v-model="query.keyword"
          ></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="env-detail__content__body">
      <list-wrap :loading="loading" :empty="total === 0" :hasCreateAuth="false">
        <el-table :data="list" class="review-table">
          <el-table-column type="expand">
            <template #default="props">
              <el-form label-position="left" class="review-table-expand">
                <el-form-item label="部署说明">
                  <span>{{ props.row.deployDescription }}</span>
                </el-form-item>
                <el-form-item label="审核说明" v-if="props.row.auditInstructions">
                  <span>{{ props.row.auditInstructions }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="index" label="序号" width="50" />
          <el-table-column label="部署类型" prop="deployType"></el-table-column>
          <el-table-column label="部署名称" prop="deployName"></el-table-column>
          <el-table-column label="申请人" prop="applicantName">
            <template #header>
              <applicant @change="(value) => handleValueChange('applyUser', value)" @change-close="handleCloseChange" />
            </template>
          </el-table-column>
          <el-table-column label="申请时间" prop="createTime">
            <template #default="scope">{{ dateFormat(scope.row.createTime) }}</template>
          </el-table-column>
          <el-table-column label="审核结果" prop="status">
            <template #header>
              <review-result
                @change="(value) => handleValueChange('reviewResult', value)"
                @change-close="handleCloseChange"
              />
            </template>
            <template #default="scope">
              <span>{{ getResultByCode(scope.row.status) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审核人" prop="reviewerName">
            <template #header>
              <reviewer @change="(value) => handleValueChange('auditUser', value)" @change-close="handleCloseChange" />
            </template>
          </el-table-column>
          <el-table-column label="部署版本" prop="deployVersion"> </el-table-column>
          <el-table-column label="部署时间" prop="publishTime">
            <template #default="scope">{{
              scope.row.status === AUDIT_RESULTS_CODE.DEPLOYED ? dateFormat(scope.row.auditTime) : ''
            }}</template>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <template #default="scope">
              <el-button
                type="text"
                size="mini"
                :disabled="scope.row.status !== 0"
                @click="handleDialogOpen(scope.row)"
                v-if="getShowBool('audit')"
                >审核</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="handleReAudit(scope.row)"
                v-if="
                  (scope.row.status === AUDIT_RESULTS_CODE.PASSED ||
                    scope.row.status === AUDIT_RESULTS_CODE.DEPLOYED) &&
                  getShowBool('audit')
                "
                >重复下发</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <packaged-pagination
          v-if="total"
          :current-page="query.page"
          :page-size="query.pageSize"
          :total="total"
          @size-change="handlePageSizeChange"
          @current-change="handlePageChange"
        ></packaged-pagination>
      </list-wrap>
    </div>
    <review-dialog ref="refReviewDialog" @success-audit="handleSuccessAudit" />
  </div>
</template>
<script>
import { reactive, ref } from 'vue';
import { getShowBool } from '@/utils/permission-show-module';
import ReviewDialog from './components/ReviewDialog';
import dateFormat from '@/utils/date-format';
import { AUDIT_RESULTS_CODE, getModuleType } from '../../demands/publish/constant';
import Applicant from './components/Applicant';
import { useReviewResult } from './utils/reviewResult';
import ReviewResult from './components/ReviewResult';
import Reviewer from './components/Reviewer';
import { userInfo } from '@/layout/messageCenter/user-info';
import { ElMessage } from 'element-plus';
import { auditDeployApply, getDeployAuditList } from '@/api/env';
import { useSaList } from '@/shared/hooks/list';

export default {
  name: 'EnvDetailDeployReview',
  components: { Reviewer, ReviewResult, Applicant, ReviewDialog },
  setup() {
    const extendQuery = reactive({
      status: null,
      applyUser: '',
      auditUser: '',
      sortField: 'createTime',
      sortType: 'descending',
    });

    const refReviewDialog = ref();
    const handleDialogOpen = (row) => {
      refReviewDialog.value.handleOpen(row);
    };

    const { loading, list, total, query, handleSearch, handlePageChange, handlePageSizeChange, fetchList } = useSaList(
      async (query) => {
        loading.value = true;
        try {
          const { data } = await getDeployAuditList({
            ...query,
            ...extendQuery,
          });
          const { count, rows = [] } = data;
          total.value = count;
          list.value = rows.map((item, index) => ({
            ...item,
            deployType: getModuleType(item.deployType),
            index: index + 1,
          }));
        } catch (e) {
          throw new Error(e);
        }
        loading.value = false;
      },
    );
    fetchList();
    const handleValueChange = (type, value) => {
      switch (type) {
        case 'applyUser':
          extendQuery.applyUser = value;
          break;
        case 'reviewResult':
          extendQuery.status = value;
          break;
        case 'auditUser':
          extendQuery.auditUser = value;
      }
    };

    const handleCloseChange = () => {
      query.page = 1;
      fetchList();
    };

    // 获取审核结果
    const { getResultByCode } = useReviewResult();

    const getRowOptionStatus = (row) => userInfo.value.userId !== row.applicant || row.status !== 0;

    const handleSuccessAudit = () => {
      fetchList();
    };

    // 审核通过时再次下发
    const handleReAudit = async (row) => {
      const auditData = {
        status: row.status,
        auditInstructions: row.auditInstructions,
        isReAudit: true,
      };
      try {
        const { code, data } = await auditDeployApply(row.id, auditData);
        if (code === 0) {
          if (data === 'exist') {
            ElMessage.error(`该${row.deployType}已存在`);
          } else {
            ElMessage.success('下发成功');
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    return {
      extendQuery,
      query,
      list,
      total,
      loading,
      getShowBool,
      refReviewDialog,
      handleDialogOpen,
      dateFormat,
      handleValueChange,
      handleCloseChange,
      getResultByCode,
      handleSearch,
      handlePageSizeChange,
      handlePageChange,
      getRowOptionStatus,
      handleSuccessAudit,
      handleReAudit,
      AUDIT_RESULTS_CODE,
    };
  },
};
</script>
