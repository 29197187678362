
import { defineComponent, reactive, ref, getCurrentInstance, Ref, inject, computed, provide } from 'vue';
import { getServiceConfig, buildServiceNew } from '@/api/servers';
import { closeReleaseDialog } from '@/views/service-management/business-service/utils/service-release-data-utils';

import VersionInput from './VersionInput.vue';
import { compare, diff, parse, Relation, valid } from './version';
import DataBaseChangeInfoVue from './DataBaseChangeInfo.vue';

import { useServiceVersion } from './release';
import { isProxyService } from '../useAddProxy';
import { ServiceConfig } from './types';
// 状态码
enum ResCode {
  Success,
}

// 配置来源
enum ConfigOrigin {
  '服务配置',
  '项目配置',
  '通用配置',
}

// 类型
enum ConfigType {
  '应用类型',
  '系统类型',
}
interface RefType {
  [attr: string]: any;
}

interface BaseFormDataType {
  serviceVersion: string;
  description: string;
  configTemplates: any[];
}
const checkVerion = (value: string, callback: Function, lastVersion: string | undefined) => {
  // 首次版本从1.0.0 开始
  if (value === '') {
    callback(new Error('版本号不能为空'));
  }
  if (!valid(value)) {
    callback(new Error('不合法的版本号'));
  }
  if (lastVersion) {
    if (compare(value, lastVersion) === Relation.Equal) {
      callback(new Error('不能等于上个版本号'));
    }
    if (compare(value, lastVersion) === Relation.Less) {
      callback(new Error('不能小于上个版本号'));
    }
    // 主版本号跨度不能大于1
    if (diff(value, lastVersion, 'major') > 1) {
      callback(new Error('主版本与上一版本跨度不能大于 1'));
    }
  } else {
    // 初始版本比较主版本必须为1
    if (parse(value)?.major !== 1) {
      callback(new Error('初始版本主版本必须为 1'));
    }
  }
  callback();
};

export default defineComponent({
  name: 'ReleaseDialog',
  components: { VersionInput, DataBaseChangeInfoVue },
  setup() {
    // ---------------代理服务不需要sql
    const isProxy = inject(isProxyService);
    const allSteps = [
      {
        title: '基本信息',
        id: 'baseInfo',
      },
      {
        title: '配置项',
        id: 'config',
      },
      {
        title: '数据库变更',
        id: 'dataBaseChange',
        hide: isProxy,
      },
    ];
    const tabMenuData = computed(() => allSteps.filter((e) => !e.hide?.value ?? e));
    // 发版默认配置是否加载完成
    let isConfigOnLoad = false;
    const releaseBaseForm: Ref<RefType> = ref({});
    const finishing: Ref<boolean> = ref(false);
    const finishStatus: Ref<boolean> = ref(false);
    // 获取组件实例
    const instance = getCurrentInstance();
    // 组件版本
    const lastVersion = ref<string>();
    // 提示信息
    function msgTips(type: string, content: string) {
      (instance as any).proxy.$message({
        type,
        message: content,
      });
    }
    const serviceId: Ref<number> = ref(0);
    // 发版数据
    const baseFormData: BaseFormDataType = reactive({
      serviceVersion: '',
      description: '',
      configTemplates: [],
    });
    const baseFormRules = {
      serviceVersion: [
        { required: true, message: '请输入版本号', trigger: 'blur' },
        {
          validator: (rule: any, value: string, callback: Function) => {
            checkVerion(value, callback, lastVersion.value);
          },
          trigger: 'blur',
        },
      ],
      description: [
        {
          required: true,
          message: '请输入使用注意事项，更新日志，版本信息、bug修复记录',
          trigger: 'blur',
        },
        {
          min: 1,
          max: 2048,
          message: '长度在 1 到 2048 个字符',
          trigger: 'blur',
        },
      ],
    };

    // 配置数据
    const configTableData: any = ref([]);
    const tableRef: any = ref(null);
    // 获取配置列表
    const getConfigList = async (id: any): Promise<void> => {
      try {
        const { code, data } = await getServiceConfig(id);
        if (code === ResCode.Success) {
          configTableData.value = (data as ServiceConfig[]).map((e) => ({
            ...e,
            defaultValue: e.sensitive ? '******' : e.defaultValue,
          }));
        } else {
          msgTips('error', '获取人员列表失败');
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    const handleSelectionChange = (data: any): void => {
      baseFormData.configTemplates = data;
    };
    function defaultSel() {
      // 默认选中的配置
      const defaultSelConfig: any = configTableData.value.filter(
        (item: any) => item.scope === ConfigOrigin['服务配置'] || item.select,
      );
      // 自动选中系统配置
      defaultSelConfig.forEach((item: any) => {
        tableRef.value.toggleRowSelection(item);
      });
    }
    const releaseDialogVisible: Ref<boolean> = ref(false);
    // 当前step
    const currentActive = ref(0);

    // 上一步
    const releasePrev = (): void => {
      if (currentActive.value > 0) {
        currentActive.value = currentActive.value - 1;
      }
    };

    // 下一步
    const releaseNext = () => {
      console.log('下一步');
      if (currentActive.value === 0) {
        // 增加校验
        releaseBaseForm.value.validate((valid: boolean) => {
          console.log('valid', valid);
          if (valid) {
            currentActive.value = currentActive.value + 1;
            if (!isConfigOnLoad) {
              isConfigOnLoad = true;
              defaultSel();
            }
          }
        });
        // await nextTick();
      } else if (currentActive.value < 3) {
        currentActive.value = currentActive.value + 1;
      }
    };
    // let startTime: any = null;
    // 打开dialog
    const openDialog = (id: number): void => {
      // startTime = data;
      releaseDialogVisible.value = true;
      serviceId.value = id;
      finishStatus.value = false;
      getConfigList(serviceId.value);
      // getUpgradeScript(serviceId);
      useServiceVersion(serviceId.value, lastVersion);
      console.log(serviceId.value);
    };

    // 初始化
    const init = (): void => {
      currentActive.value = 0;
      // baseFormData.serviceVersion = '';
      baseFormData.description = '';
      baseFormData.configTemplates = [];
      configTableData.value = [];
      isConfigOnLoad = false;
    };

    // 关闭dialog
    const closeDialog = (): void => {
      init();
      releaseDialogVisible.value = false;
      closeReleaseDialog();
      // 取消发版
      // if (startTime && !finishStatus.value) {
      //   cancelRelease({
      //     startTime,
      //     serviceId: serviceId.value,
      //   });
      // }
    };
    const dataChangeRef: any = ref(null);

    // 完成
    const finished = async (): Promise<void> => {
      finishing.value = true;
      finishStatus.value = true;
      // 获取数据库变更信息
      try {
        const { ...formData } = baseFormData;
        const data: any = {
          ...formData,
          serviceId: serviceId.value,
          dataChanges: [],
        };
        if (!isProxy?.value) {
          // 过滤掉没有勾选变更的模型
          const dataChangeListId: any = [];
          const dataChangeObj = dataChangeRef.value.getDataChangeReq();
          // eslint-disable-next-line no-restricted-syntax
          for (const key in dataChangeObj) {
            const isFind: any = dataChangeObj[key].find((item: any) => item && item.length > 0);
            if (isFind) {
              dataChangeListId.push(key);
            }
          }
          data.dataChanges = dataChangeListId;
        }
        // 发版
        const { code } = await buildServiceNew(data);
        if (code === ResCode.Success) {
          finishing.value = false;
          msgTips('success', '发版成功！');
        } else {
          msgTips('error', '发版失败！');
        }
        closeDialog();
      } catch (error) {
        closeDialog();
        finishing.value = false;
        console.log('error', error);
      }
    };
    // 服务默认选择类型
    const handleSelAble = (row: any) => row.scope !== 0;

    provide('serviceId', serviceId.value);
    const isFullScreen: Ref<boolean> = ref(false);
    const handleFullScreen = () => {
      isFullScreen.value = !isFullScreen.value;
    };

    return {
      releaseDialogVisible,
      baseFormData,
      baseFormRules,
      currentActive,
      tabMenuData,
      openDialog,
      closeDialog,
      releaseNext,
      configTableData,
      handleSelectionChange,
      ConfigOrigin,
      ConfigType,
      tableRef,
      releasePrev,
      finished,
      releaseBaseForm,
      finishing,
      handleSelAble,
      lastVersion,
      isProxy,
      serviceId,
      handleFullScreen,
      isFullScreen,
      dataChangeRef,
    };
  },
});
