<template>
  <div class="env-detail__content">
    <div class="env-detail__content__head">
      <el-button type="primary" icon="el-icon-plus" @click="handleDialogOpen" v-if="getShowBool('add')">{{
        env === ENV_ENUM.TEST ? '引入' : '前往申请'
      }}</el-button>
    </div>
    <div class="env-detail__content__body">
      <services-merge-list
        :env="env"
        :handle-create="handleDialogOpen"
        :create-label="env === ENV_ENUM.TEST ? '引入' : '前往申请'"
        ref="serviceMergeList"
      ></services-merge-list>
    </div>
    <import-dialog ref="refImportDialog" import-type="merge-service" @complete="handleRefreshList" />
    <deploy-dialog ref="refDeployDialog" deploy-type="merge-service" />
  </div>
</template>
<script>
import { ref } from 'vue';
import { userInfo } from '@/layout/messageCenter/user-info';
import { getShowBool } from '@/utils/permission-show-module';
import { useEnvId } from './detail';
import ImportDialog from './components/ImportDialog';
import { ENV_ENUM } from '../type';
import { computeStatusLabel, statusColor } from '@/views/service-management/business-service/utils/service-status-map';
import DeployDialog from './components/DeployDialog';
import ServicesMergeList from '@/views/services-merge-deploy/list/List.vue';
export default {
  name: 'EnvDetailMainFe',
  components: {
    DeployDialog,
    ImportDialog,
    ServicesMergeList,
  },
  props: {
    env: {
      type: String,
      default: '',
    },
    envData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const envId = useEnvId();
    const refImportDialog = ref();
    const refDeployDialog = ref();
    const serviceMergeList = ref();
    const handleDialogOpen = () => {
      if (props.env === ENV_ENUM.TEST) {
        refImportDialog.value.handleOpen();
      } else {
        refDeployDialog.value.handleOpen();
      }
    };
    const handleRefreshList = () => {
      serviceMergeList.value.handleRefresh();
    };
    return {
      envId,
      userInfo,
      getShowBool,
      handleDialogOpen,
      refImportDialog,
      refDeployDialog,
      serviceMergeList,
      handleRefreshList,
      computeStatusLabel,
      statusColor,
      ENV_ENUM,
    };
  },
};
</script>
