import { ref } from 'vue';
import { changeUsers } from '@/views/demands/publish/constant';
import { getApplyOrAuditUser } from '@/api/env';

// 双向绑定选中的值
const selectedApplicant = ref();

// select onChange事件
const handleApplicantChange = (val: Array<string>) => {
  selectedApplicant.value = val;
};

// 申请人下拉列表
const applicantList = ref();

// 获取申请人列表
const fetchList = async () => {
  const { data } = await getApplyOrAuditUser();
  applicantList.value = changeUsers(data.applicants);
};

export const useApplicant = (initList = false) => {
  if (initList) {
    fetchList();
  }
  return {
    selectedApplicant,
    handleApplicantChange,
    applicantList,
  };
};
