<template>
  <el-dialog title="部署审核" v-model="visible" width="600px" @closed="handleClose">
    <div class="add-publish-set">
      <el-form :model="reviewForm" ref="reviewFormRef">
        <el-form-item
          label="审核说明"
          prop="reviewDescription"
          :rules="[{ required: true, message: '请输入审核说明', trigger: 'blur' }]"
        >
          <el-input
            v-model="reviewForm.reviewDescription"
            type="textarea"
            :rows="8"
            maxlength="1024"
            placeholder="请输入审核说明"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleFormSubmit(true)">通过</el-button>
        <el-button @click="handleFormSubmit(false)">不通过</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref } from 'vue';
import { AUDIT_RESULTS_CODE } from '@/views/demands/publish/constant';
import { ElMessage } from 'element-plus';
import { auditDeployApply } from '@/api/env';

export default {
  name: 'ReviewDialog',
  emits: ['success-audit'],
  setup(props, { emit }) {
    const visible = ref(false);
    const reviewForm = reactive({
      id: '',
      reviewDescription: '',
    });

    const reviewFormRef = ref();
    const handleOpen = (row) => {
      visible.value = true;
      reviewForm.id = row.id;
    };
    const handleClose = () => {
      reviewFormRef.value.resetFields();
      visible.value = false;
    };
    const handleFormSubmit = (status) => {
      reviewFormRef.value.validate(async (valid) => {
        if (valid) {
          const reviewData = {
            status: status ? AUDIT_RESULTS_CODE.PASSED : AUDIT_RESULTS_CODE.FAILED,
            auditInstructions: reviewForm.reviewDescription,
          };
          try {
            const { code } = await auditDeployApply(reviewForm.id, reviewData);
            if (code === 0) {
              ElMessage({
                type: 'success',
                message: '审核成功',
              });
              emit('success-audit');
            }
          } catch (e) {
            console.log(e);
          }
          handleClose();
        }
      });
    };

    return {
      visible,
      handleOpen,
      reviewForm,
      reviewFormRef,
      handleClose,
      handleFormSubmit,
    };
  },
};
</script>
