<template>
  <el-dialog v-model="visible" :title="isEdit ? '编辑合并部署包' : '新建合并部署包'" width="450px">
    <el-form :model="formData" :rules="rules" ref="formRef" label-width="80px" label-position="right">
      <el-form-item label="服务列表" prop="dependencies">
        <el-cascader
          v-model="formDependencies"
          placeholder="请选择要合并的服务"
          style="width: 100%"
          :options="mergeServiceOptions"
          :props="{ multiple: true }"
          @change="handleServiceChange"
          :filterable="true"
        />
      </el-form-item>
      <el-form-item label="英文名" prop="shortName">
        <el-input v-model="formData.shortName" placeholder="请输入英文名称" :disabled="isEdit"></el-input>
      </el-form-item>
      <el-form-item label="中文名" prop="zhName">
        <el-input v-model="formData.zhName" placeholder="请输入中文名称"></el-input>
      </el-form-item>
      <el-form-item label="负责人" prop="ownerUidList">
        <sa-user-selector :value="formData.ownerUidList" @change="handleOwnerChange" :filter-by-project="true" />
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input v-model="formData.description" placeholder="请输入部署包描述，最多1024个字符"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="addNewMerge" :loading="submitting">确定</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref } from 'vue';
import { addMergeService, getAvailableDependencies, queryDetail, updateService } from '@/api/mergeDeploy';
import { ElMessage } from 'element-plus';
export default {
  name: 'MergeDeployDialog',
  emits: ['successAdd'],

  setup(props, { emit }) {
    const visible = ref(false);
    const formData = reactive({
      shortName: '',
      zhName: '',
      ownerUidList: [],
      description: '',
      dependencies: [],
    });
    const formRef = ref();
    const rules = {
      shortName: [
        { required: true, message: '请输入英文名称', trigger: 'blur' },
        {
          min: 1,
          max: 45,
          message: '英文名长度不能超过45个字符',
          trigger: 'blur',
        },
        {
          pattern: /^(?!-)(?!.*-$)[a-z0-9-]+$/,
          message: '仅支持小写英文、数字、中划线，不能以中划线开头和结尾',
          trigger: 'blur',
        },
      ],
      zhName: [
        { required: true, message: '请输入中文名称', trigger: 'blur' },
        {
          min: 1,
          max: 60,
          message: '中文名称不能超过60个字符',
          trigger: 'blur',
        },
      ],
      description: [
        { required: true, message: '请输入部署包描述', trigger: 'blur' },
        {
          min: 1,
          max: 1024,
          message: '描述不能超过1024个字符',
          trigger: 'blur',
        },
      ],
      dependencies: [
        { required: true, message: '请选择服务', trigger: 'blur' },
        {
          validator(rule, value, callback) {
            if (value.length < 2 || value.length > 10) {
              callback(new Error('合并服务在2-10个之间!'));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
      ],
    };
    let startDependencies = [];
    const formDependencies = ref([]);
    const mergeServiceOptions = ref([]);
    const handleServiceChange = () => {
      if (startDependencies.length < formDependencies.value.length) {
        // 新增：判断是否新增同服务的不同版本，是的话则去掉老的服务，保留新的服务
        if (formDependencies.value.length - startDependencies.length > 1) {
          // 如果是全选则默认选择master选项的下一个选项
          const end = formDependencies.value[startDependencies.length + 1];
          formDependencies.value = formDependencies.value.filter((k) => !(end[0] === k[0]));
          formDependencies.value.push(end);
        }
        const tmp = {};
        for (const i in formDependencies.value) {
          if (tmp[formDependencies.value[i][0]]) {
            // 一级节点出现2次
            const value = tmp[formDependencies.value[i][0]];
            formDependencies.value = formDependencies.value.filter((k) => !(value[0] === k[0] && value[1] === k[1]));
            return;
          }
          tmp[formDependencies.value[i][0]] = [formDependencies.value[i][0], formDependencies.value[i][1]];
        }
      }
      // 实现二级单选
      if (formDependencies.value.length > 0) {
        formData.dependencies = formDependencies.value.map(
          (item) => mergeServiceOptions.value[item[0]].children[item[1]].conf,
        );
      }
      startDependencies = formDependencies.value;
    };
    // 获取服务依赖列表
    const getAvailableService = async (payload) => {
      try {
        const { data } = await getAvailableDependencies(payload);
        const formDependenciesTmp = [];
        const serviceData = {};
        for (const i in formData.dependencies) {
          const item = formData.dependencies[i];
          serviceData[item.serviceName] = item;
        }
        const arr = data.map((item, i) => {
          const value = [];
          if (formData.dependencies) {
            if (serviceData[item.serviceName]) {
              value.push(i);
            }
          }
          const optionsList = {
            value: i,
            label: `${item.serviceName.split('.').pop()} (${item.zhName})`,
            children: item.versions.map((versionItem, j) => {
              if (formData.dependencies) {
                if (
                  serviceData[item.serviceName] &&
                  serviceData[item.serviceName]?.serviceVersion === versionItem.version &&
                  serviceData[item.serviceName]?.versionType === versionItem.versionType
                ) {
                  value.push(j);
                  formDependenciesTmp.push(value);
                }
              }
              return {
                label: `${versionItem.version}`,
                value: j,
                conf: {
                  serviceName: item.serviceName,
                  serviceVersion: versionItem.version,
                  versionType: versionItem.versionType,
                },
              };
            }),
          };
          return optionsList;
        });
        console.log(formData);
        mergeServiceOptions.value = arr.flat(Infinity);
        if (formData.dependencies) {
          formDependencies.value = formDependenciesTmp;
          startDependencies = formDependenciesTmp;
        }
      } catch (e) {
        console.log(e);
      }
    };
    // 回读获依赖服务详情
    const getDependenciesDetail = async (name) => {
      try {
        const {
          data: { dependencies },
        } = await queryDetail(name);
        formData.dependencies = dependencies;
      } catch (e) {
        console.log(e);
      }
    };

    const isEdit = ref(false);
    const handleOpen = async (row) => {
      if (row?.name) {
        console.log('编辑');
        await getDependenciesDetail({ serviceFullName: row.name });
        await getAvailableService({ serviceFullName: row.name });
        isEdit.value = true;
        formData.name = row.name;
        formData.shortName = row.name.split('.').pop();
        formData.zhName = row.description;
        formData.ownerUidList = row.owners.map((item) => item.userId);
        formData.description = row.detail;
      } else {
        formData.zhName = '';
        formData.shortName = '';
        formData.description = '';
        formData.name = '';
        formData.dependencies = [];
        isEdit.value = false;
        getAvailableService();
      }
      visible.value = true;
    };

    const handleCancel = () => {
      visible.value = false;
      formRef.value.resetFields();
      formDependencies.value = [];
    };

    const handleOwnerChange = (val) => {
      formData.ownerUidList = val;
    };

    const submitting = ref(false);
    const addNewMerge = async () => {
      const valid = await formRef.value.validate();
      if (!valid) {
        return false;
      }
      submitting.value = true;
      const params = {
        ...formData,
      };
      let api;
      let msg;
      if (isEdit.value === true) {
        api = updateService;
        msg = '编辑合并服务成功！';
      } else {
        api = addMergeService;
        msg = '新建合并服务成功！';
      }
      try {
        const { code } = await api({ ...params });
        if (code === 0) {
          ElMessage.success(msg);
          emit('successAdd');
        }
      } catch (e) {
        console.log(e);
      }
      submitting.value = false;
      handleCancel();
    };

    return {
      visible,
      formData,
      handleOpen,
      mergeServiceOptions,
      getAvailableService,
      rules,
      submitting,
      addNewMerge,
      formRef,
      handleCancel,
      handleOwnerChange,
      handleServiceChange,
      formDependencies,
      isEdit,
    };
  },
};
</script>

<style scoped>
.dialog-footer {
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}
</style>
