<template>
  <el-dialog v-model="visible" title="合并部署包详情" width="60%">
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane label="服务列表" name="list">
        <sub-service-list :serviceId="serviceId" />
      </el-tab-pane>
      <el-tab-pane label="服务配置" name="config">
        <div class="config">
          <server-config-info :status="status" :id="serviceId" config-type="mergeService" />
        </div>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import SubServiceList from '@/views/services-merge-deploy/components/SubServiceList';
import ServerConfigInfo from '@/views/service-management/business-service/components/ServerConfigInfo';

export default {
  name: 'MergeServiceDetail',
  components: { ServerConfigInfo, SubServiceList },

  setup() {
    const visible = ref(false);
    const serviceId = ref(0);
    const status = ref(0);
    const handleOpen = (row) => {
      serviceId.value = row.id;
      visible.value = true;
      status.value = row.status;
    };

    const activeName = ref('list');

    const handleClose = () => {
      visible.value = false;
    };

    return {
      visible,
      handleOpen,
      handleClose,
      activeName,
      serviceId,
      status,
    };
  },
};
</script>

<style scoped>
.dialog-footer {
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}
.config {
  height: 600px;
}
</style>
