<template>
  <el-form :model="form" ref="fromRef">
    <el-form-item label="英文名" :label-width="labelWidth" v-if="edit || env === 'PROD'">{{ form.name }}</el-form-item>
    <el-form-item
      v-else
      label="英文名"
      :label-width="labelWidth"
      prop="name"
      :rules="[
        { required: true, message: '请输入英文名称', trigger: 'blur' },
        { min: 3, max: 20, message: '请输入3到20个字符', trigger: 'blur' },
        {
          validator: checkProjectName,
          trigger: 'blur',
        },
      ]"
    >
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item
      label="中文名"
      :label-width="labelWidth"
      prop="description"
      :rules="[
        { required: true, message: '请输入中文名称', trigger: 'blur' },
        { min: 3, max: 20, message: '请输入3到20个字符', trigger: 'blur' },
      ]"
    >
      <el-input v-model="form.description" ref="projectDescriptionInput"></el-input>
    </el-form-item>
    <el-form-item
      label="负责人"
      prop="owner"
      :label-width="labelWidth"
      :rules="[{ required: true, message: '请选择负责人', trigger: 'blur' }]"
    >
      <sa-user-selector :value="form.owner" @change="handleOwnerChange" :filter-by-project="true" />
    </el-form-item>
    <el-form-item
      label="描述"
      :label-width="labelWidth"
      prop="remark"
      :rules="[{ min: 0, max: 512, message: '最多支持512个字符', trigger: 'blur' }]"
    >
      <el-input v-model="form.remark" type="textarea" :rows="5"></el-input>
    </el-form-item>
    <el-form-item class="form-btns">
      <el-button type="primary" :loading="submitting" @click="onSubmit">{{ edit ? '确定' : '下一步' }}</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { defineComponent, reactive, ref, watch } from 'vue';
import { projectNameTest, updateProject } from '@/api/project/project';
import { codeTemplateList } from '@/views/project-management/utils/project-data-utils';
import { userInfo } from '@/layout/messageCenter/user-info';
import { ElMessage } from 'element-plus';
import { ENV_ENUM } from '../../type';
export default defineComponent({
  components: {},
  props: {
    baseForm: {
      type: Object,
      default() {
        return {};
      },
    },
    visible: { type: Boolean },
    // 是否编辑态
    edit: {
      type: Boolean,
      default: false,
    },
    env: {
      type: String,
      default: ENV_ENUM.TEST,
    },
  },
  emits: ['complete', 'success'],
  setup(props, { emit }) {
    const form = reactive({ ...props.baseForm.base });
    if (props.env === ENV_ENUM.PROD) {
      form.name = 'prod';
    }
    const submitting = ref(false);
    const fromRef = ref(null);
    const owners = [
      {
        id: userInfo.value.userId,
        userName: userInfo.value.userName,
        displayName: userInfo.value.displayName,
      },
    ];
    form.owner = owners.map((u) => u.id);
    form.owners = [...owners];

    watch(
      () => props.visible,
      (val) => {
        if (!val) {
          fromRef.value.resetFields();
        }
      },
    );
    const checkEnglishName = () => {
      if (!form.name) {
        return;
      }
      projectNameTest({ name: form.name });
    };

    const checkProjectName = (rule, value, callback) => {
      const reg = /^[a-z][a-z0-9-]+[a-z0-9]$/;
      if (!reg.test(value)) {
        callback(new Error('输入小写字母/数字/中横线，字母开头，字母/数字结尾'));
      } else if (['prod', 'product', 'production', 'test'].includes(value)) {
        callback(new Error(`${value}为系统保留词，请使用其他名称`));
      } else {
        projectNameTest({ name: form.name }).then(({ data }) => {
          if (data.usable) {
            callback();
          } else {
            callback(new Error('存在同名项目或环境'));
          }
        });
      }
    };

    const handleOwnerChange = (value, users) => {
      form.owner = value;
      form.owners = users;
    };

    const onSubmit = () => {
      fromRef.value.validate(async (valid) => {
        if (!valid) {
          return;
        }
        if (props.edit) {
          try {
            submitting.value = true;
            const saveData = {
              description: form.description,
              remark: form.remark,
            };
            saveData.owner = Array.isArray(form.owner) ? (form.owner || []).join(',') : form.owner;
            await updateProject(form.id, saveData);
            ElMessage.success('更新成功');
            emit('success');
          } catch (e) {
            console.log(e);
          }
          submitting.value = false;
        } else {
          emit('complete', {
            currentStep: 1,
            nextStep: 2,
            form,
          });
        }
      });
    };
    const handleCancel = () => {
      emit('cancel', {
        currentStep: 1,
      });
    };
    return {
      form,
      fromRef,
      owners,
      labelWidth: '90px',
      checkEnglishName,
      codeTemplateList,
      checkProjectName,
      onSubmit,
      handleCancel,
      handleOwnerChange,
      submitting,
    };
  },
});
</script>
