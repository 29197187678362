<template>
  <div class="env-detail__content">
    <div class="env-detail__content__head">
      <el-row style="margin-bottom: 10px">
        <el-col :span="6" style="text-align: left">
          <el-button
            icon="el-icon-plus"
            type="primary"
            @click="handleDialogOpen"
            style="width: 90px"
            v-if="getShowBool('addApply')"
            >新建</el-button
          >
        </el-col>
        <el-col :offset="12" :span="6" style="text-align: right">
          <el-input
            style="max-width: 300px; margin-left: auto"
            placeholder="请输入部署名称"
            suffix-icon="el-icon-search"
            @input="handleSearch"
            v-model="query.keyword"
          ></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="env-detail__content__body">
      <list-wrap
        :loading="loading"
        :empty="total === 0"
        :hasCreateAuth="getShowBool('add')"
        :handleCreate="handleDialogOpen"
      >
        <el-table :data="list" class="publish-table">
          <el-table-column type="expand">
            <template #default="props">
              <el-form label-position="left" class="publish-table-expand">
                <el-form-item label="部署说明">
                  <span>{{ props.row.deployDescription }}</span>
                </el-form-item>
                <el-form-item label="审核说明" v-if="props.row.auditInstructions">
                  <span>{{ props.row.auditInstructions }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="index" label="序号" width="50" />
          <el-table-column label="部署类型" prop="deployType"> </el-table-column>
          <el-table-column label="部署名称" prop="deployName"> </el-table-column>
          <el-table-column label="申请人" prop="applicantName">
            <template #header>
              <applicant @change="(value) => handleValueChange('applyUser', value)" @change-close="handleCloseChange" />
            </template>
          </el-table-column>
          <el-table-column label="申请时间" prop="createTime">
            <template #default="scope">{{ dateFormat(scope.row.createTime) }}</template>
          </el-table-column>
          <el-table-column label="审核结果" prop="status">
            <template #header>
              <review-result
                @change="(value) => handleValueChange('reviewResult', value)"
                @change-close="handleCloseChange"
              />
            </template>
            <template #default="scope">
              <span>{{ getResultByCode(scope.row.status) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审核人" prop="reviewerName">
            <template #header>
              <reviewer @change="(value) => handleValueChange('auditUser', value)" @change-close="handleCloseChange" />
            </template>
          </el-table-column>
          <el-table-column label="部署版本" prop="deployVersion"> </el-table-column>
          <el-table-column label="部署时间" prop="publishTime">
            <template #default="scope">{{
              scope.row.status === AUDIT_RESULTS_CODE.DEPLOYED ? dateFormat(scope.row.auditTime) : ''
            }}</template>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <template #default="scope">
              <el-button
                type="text"
                size="mini"
                @click="onEdit(scope.row)"
                :disabled="isOptionDisabled(scope.row)"
                v-if="getShowBool('update')"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="onDelete(scope.row)"
                :disabled="isOptionDisabled(scope.row)"
                v-if="getShowBool('deleteApply')"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <packaged-pagination
          v-if="total"
          :current-page="query.page"
          :page-size="query.pageSize"
          :total="total"
          @size-change="handlePageSizeChange"
          @current-change="handlePageChange"
        ></packaged-pagination>
      </list-wrap>
    </div>
    <deploy-dialog ref="refDeployDialog" deploy-type="apply" @success-add="handleSuccessAdd"></deploy-dialog>
  </div>
</template>
<script>
import DeployDialog from './components/DeployDialog';
import { reactive, ref } from 'vue';
import { AUDIT_RESULTS_CODE, getModuleType } from '../../demands/publish/constant';
import dateFormat from '@/utils/date-format';
import { getShowBool } from '@/utils/permission-show-module';
import Applicant from './components/Applicant';
import { useReviewResult } from './utils/reviewResult';
import ReviewResult from './components/ReviewResult';
import Reviewer from './components/Reviewer';
import { userInfo } from '@/layout/messageCenter/user-info';
import { ElMessage, ElMessageBox } from 'element-plus';
import { deleteDeployApply, getDeployApplyList } from '@/api/env';
import { useSaList } from '@/shared/hooks/list';

export default {
  name: 'EnvDetailDeployApply',
  components: { Reviewer, ReviewResult, Applicant, DeployDialog },
  setup() {
    // 子组件ref及打开弹窗方法
    const refDeployDialog = ref();
    const handleDialogOpen = () => {
      refDeployDialog.value.handleOpen();
    };
    const extendQuery = reactive({
      status: null,
      applyUser: '',
      auditUser: '',
      sortField: 'createTime',
      sortType: 'descending',
    });
    const { loading, list, total, query, handleSearch, handlePageChange, handlePageSizeChange, fetchList } = useSaList(
      async (query) => {
        loading.value = true;
        try {
          const { data } = await getDeployApplyList({
            ...query,
            ...extendQuery,
          });
          const { count, rows = [] } = data;
          total.value = count;
          list.value = rows.map((item, index) => ({
            ...item,
            deployType: getModuleType(item.deployType),
            index: index + 1,
          }));
        } catch (e) {
          throw new Error(e);
        }
        loading.value = false;
      },
    );
    fetchList();

    // 成功添加部署申请
    const handleSuccessAdd = () => {
      fetchList();
    };

    const getDeployTypeName = (type) => (type === 1 ? '服务' : '应用');

    const handleValueChange = (type, value) => {
      switch (type) {
        case 'applyUser':
          extendQuery.applyUser = value;
          break;
        case 'reviewResult':
          extendQuery.status = value;
          break;
        case 'auditUser':
          extendQuery.auditUser = value;
      }
    };

    const handleCloseChange = () => {
      query.page = 1;
      fetchList();
    };

    // 获取审核结果
    const { getResultByCode } = useReviewResult();

    const onEdit = (row) => {
      refDeployDialog.value.handleEdit(row);
    };

    const onDelete = async (row) => {
      ElMessageBox.confirm(`是否删除部署申请?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code } = await deleteDeployApply(row.id);
          if (code === 0) {
            ElMessage({
              type: 'success',
              message: '删除成功',
            });
            await fetchList();
          } else {
            ElMessage({
              type: 'error',
              message: '删除失败',
            });
          }
        })
        .catch(() => {
          console.log();
        });
    };

    const isOptionDisabled = (row) => userInfo.value.userId !== row.applyUser || row.status !== 0;

    return {
      refDeployDialog,
      handleDialogOpen,
      query,
      fetchList,
      list,
      total,
      loading,
      extendQuery,
      handleSearch,
      handleValueChange,
      handleCloseChange,
      getResultByCode,
      dateFormat,
      getShowBool,
      onEdit,
      onDelete,
      isOptionDisabled,
      handlePageSizeChange,
      handlePageChange,
      getDeployTypeName,
      handleSuccessAdd,
      AUDIT_RESULTS_CODE,
    };
  },
};
</script>
