<template>
  <div class="env-detail__content">
    <div class="env-detail__content__head">
      <el-button type="primary" icon="el-icon-plus" @click="handleDialogOpen" v-if="getShowBool('add')">{{
        env === ENV_ENUM.TEST ? '引入' : '前往申请'
      }}</el-button>
    </div>
    <div class="env-detail__content__body">
      <list-wrap
        :handleCreate="handleDialogOpen"
        :loading="loading"
        :empty="total === 0"
        :hasCreateAuth="getShowBool('add')"
        :create-label="env === ENV_ENUM.TEST ? '引入' : '前往申请'"
      >
        <el-table :data="list">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="name" label="模块英文名">
            <template #default="scope">
              <router-link
                v-if="getShowBool('selectDetail') && scope.row.delState === 0"
                :to="{
                  path: `/env/${env.toLowerCase()}/${envId}/micro-app/${scope.row.id}`,
                  query: {
                    env: env,
                    envName: envData.name,
                    pp: query.page,
                  },
                }"
              >
                <service-name :name="scope.row.name" />
              </router-link>
              <service-name :name="scope.row.name" v-else />
            </template>
          </el-table-column>
          <el-table-column prop="description" label="模块中文名"></el-table-column>
          <el-table-column prop="status" label="运行状态">
            <template #default="scope">
              <template v-if="scope.row.delState > 0">
                <span v-if="scope.row.delState === 1" class="sa-status-text sa-text-progress">删除中</span>
                <span v-if="scope.row.delState === 3" class="sa-status-text sa-text-error">删除异常</span>
              </template>
              <template v-else>
                <span :style="{ color: statusColor[scope.row.status] }">{{
                  computeStatusLabel(scope.row.initTimes)[scope.row.status]
                }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="classification" label="分类">
            <template #header>
              <classification-filter
                @change="(value) => handleValueChange('classification', value)"
                @change-close="handleCloseChange"
              />
            </template>
            <template #default="scope"> {{ getClassificationName(scope.row.classification) }} </template>
          </el-table-column>
          <el-table-column property="tag" label="标签">
            <template #header>
              <tag-filter @change="(value) => handleValueChange('tags', value)" @change-close="handleCloseChange" />
            </template>
            <template #default="scope">
              {{ getTagName(scope.row.tag) }}
            </template>
          </el-table-column>
          <el-table-column prop="source" label="来源"></el-table-column>
          <el-table-column prop="source" label="形式"></el-table-column>
          <el-table-column prop="serviceVersion" label="版本">
            <template #default="scope">
              <Version :data="scope.row"></Version>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="source" label="形式">
            <template #default="scope">
              <span>{{ scope.row.appearance === 1 ? '原生' : '注册-代理' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="serviceVersion" label="版本"></el-table-column> -->
          <el-table-column label="操作">
            <template #default="scope">
              <div class="row-actions">
                <template v-if="getShowBool('delete')">
                  <a v-if="scope.row.delState !== 1" @click="onRemove(scope.row)">删除</a>
                  <span class="is-disabled" v-else>删除</span>
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <packaged-pagination
          @size-change="handlePageSizeChange"
          @current-change="handlePageChange"
          :current-page="query.page"
          :page-size="query.pageSize"
          layout="sizes, prev, pager, next, jumper"
          :total="total"
          v-if="list.length > 0"
        >
        </packaged-pagination>
      </list-wrap>
    </div>
    <import-dialog ref="refImportDialog" import-type="micro-app" @complete="fetchList" />
    <deploy-dialog ref="refDeployDialog" deploy-type="micro-app" />
  </div>
</template>
<script>
import { getShowBool } from '@/utils/permission-show-module';
import PackagedPagination from '@/components/pagination/Index.vue';
import { onBeforeUnmount, reactive, ref } from 'vue';
import ImportDialog from './components/ImportDialog';
import { ElMessage, ElMessageBox } from 'element-plus';
import { statusColor, computeStatusLabel } from '../../service-management/business-service/utils/service-status-map';
import { getApps, deleteAppById } from '@/api/app';
import ClassificationFilter from './components/Classification';
import { useClassification } from './utils/classification';
import TagFilter from './components/Tags';
import { useTags } from './utils/tags';
import { useEnvId } from './detail';
import { ENV_ENUM } from '../type';
import { getServiceSourceName } from '../../service-management/business-service/utils/service-data-utils';
import Version from '@/views/application-module/components/upgrade-instance/Version.vue';
import DeployDialog from './components/DeployDialog';
import { useSaList } from '@/shared/hooks/list';

export default {
  name: 'EnvDetailMicroFe',
  components: { DeployDialog, ImportDialog, PackagedPagination, TagFilter, ClassificationFilter, Version },
  props: {
    env: {
      type: String,
      default: ENV_ENUM.TEST,
    },
    envData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { getClassificationName } = useClassification();
    const { getTagName } = useTags();
    const envId = useEnvId();
    const extendQuery = reactive({
      env: props.env,
      classification: '',
      tags: [],
    });

    const fetchTimer = ref();

    const { list, total, loading, query, handleSearch, handlePageSizeChange, handlePageChange, fetchList } = useSaList(
      async (query, useLoading = true) => {
        if (useLoading) {
          loading.value = true;
        }
        clearTimeout(fetchTimer.value);
        try {
          const {
            data: { rows, count },
          } = await getApps({
            ...query,
            ...extendQuery,
          });
          rows.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.source = getServiceSourceName(item.serviceSource);
          });
          list.value = rows;
          total.value = count;
          fetchTimer.value = setTimeout(() => fetchList(false), 5000);
        } catch (e) {}
        loading.value = false;
      },
    );

    fetchList();

    const refImportDialog = ref();
    const refDeployDialog = ref();
    const handleDialogOpen = () => {
      if (props.env === ENV_ENUM.TEST) {
        refImportDialog.value.handleOpen();
      } else {
        refDeployDialog.value.handleOpen('micro-app');
      }
    };

    const handleValueChange = (type, value) => {
      type === 'classification' ? (extendQuery.classification = value) : (extendQuery.tags = value);
    };

    const handleCloseChange = () => {
      query.page = 1;
      fetchList();
    };

    const onRemove = (row) => {
      ElMessageBox.confirm('删除动作不可撤销, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { code } = await deleteAppById(row.id);
        if (code === 0) {
          ElMessage({
            type: 'success',
            message: '删除命令下发成功！',
          });
          fetchList();
        } else {
          ElMessage({
            type: 'error',
            message: '删除命令下发失败！',
          });
        }
      });
    };
    onBeforeUnmount(() => {
      clearTimeout(fetchTimer.value);
    });
    return {
      getShowBool,
      query,
      total,
      list,
      extendQuery,
      refImportDialog,
      refDeployDialog,
      handleDialogOpen,
      onRemove,
      statusColor,
      computeStatusLabel,
      loading,
      fetchList,
      getClassificationName,
      getTagName,
      handleValueChange,
      handleCloseChange,
      handleSearch,
      handlePageSizeChange,
      handlePageChange,
      envId,
      ENV_ENUM,
    };
  },
};
</script>
