<template>
  <el-dialog
    title="提示"
    v-model="visible"
    width="500px"
    @close="closeDialog"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="del-body">
      <i class="icon el-icon-warning"></i>
      <div class="content">
        <span>{{ message }}</span>
        <div class="del-sync">
          <el-checkbox v-model="sync" label="同步删除其他环境数据" size="large" />
          <el-tooltip placement="top">
            <template #content>
              此{{ serviceTypeLabel }}如果已经被引入至其他环境，<br />则对应的{{ serviceTypeLabel }}实例将会被一并删除
            </template>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" :loading="submitting" @click="onSubmit"> 确定 </el-button>
      <el-button @click="closeDialog">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'DeleteDialog',
  props: {
    serviceType: {
      type: String,
      default: 'service',
    },
    serviceTypeLabel: {
      type: String,
      default: '服务',
    },
  },
  setup() {
    const submitting = ref(false);
    const visible = ref(false);
    const message = ref('');
    const sync = ref(true);
    const onConfirm = ref(null);
    // 开启对话框
    const openDialog = (
      msg,
      handleConfirm = () => {
        //
      },
    ) => {
      visible.value = true;
      message.value = msg;
      sync.value = true;
      onConfirm.value = handleConfirm;
    };

    // 关闭dialog
    const closeDialog = () => {
      visible.value = false;
    };

    const onSubmit = async () => {
      submitting.value = true;
      try {
        onConfirm.value(sync.value);
        closeDialog();
      } catch (error) {
        console.log(error);
      }
      submitting.value = false;
    };

    return {
      submitting,
      visible,
      message,
      sync,
      openDialog,
      closeDialog,
      onSubmit,
    };
  },
});
</script>
<style lang="scss" scoped>
.del-body {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  .icon {
    font-size: 24px;
    color: #e6a23c;
    flex-shrink: 0;
  }

  .content {
    flex: 1;
    margin-left: 10px;
    padding-top: 4px;
  }
}
.del-sync {
  margin: 20px 0;
  display: flex;
  align-items: center;
  i {
    font-size: 16px;
    margin-left: 5px;
  }
}
.dialog-footer {
  text-align: center;
}
</style>
