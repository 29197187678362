<template>
  <i class="el-icon-search"></i>
  <el-popover placement="bottom" :width="200" trigger="manual" :visible="visible">
    <template #reference>
      <el-button type="text" @click="handleFilterOpen">审核人</el-button>
    </template>
    <el-select v-model="selectedReviewer" placeholder="请选择审核人" clearable multiple @change="handleChange">
      <el-option v-for="item in reviewerList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </el-popover>
  <div class="black-hovers" @click="handleOverlayClick()" v-if="visible"></div>
</template>

<script>
import { ref } from 'vue';
import { useReviewer } from '../utils/reviewer';

export default {
  name: 'Reviewer',
  emits: ['change', 'change-close'],
  setup(props, { emit }) {
    const visible = ref(false);
    const handleFilterOpen = () => {
      visible.value = true;
    };

    const { selectedReviewer, handleReviewerChange, reviewerList } = useReviewer(true);

    const handleChange = (val) => {
      handleReviewerChange(val);
      emit('change', val);
    };

    const handleOverlayClick = () => {
      emit('change-close', selectedReviewer.value);
      visible.value = false;
    };
    return {
      visible,
      handleFilterOpen,
      handleOverlayClick,
      handleChange,
      reviewerList,
      selectedReviewer,
    };
  },
};
</script>
