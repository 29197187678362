<template>
  <i class="el-icon-search"></i>
  <el-popover placement="bottom" :width="200" trigger="manual" :visible="visible">
    <template #reference>
      <el-button type="text" @click="handleFilterOpen">审核结果</el-button>
    </template>
    <el-select v-model="selectedResult" placeholder="请选择审核结果" clearable @change="handleChange">
      <el-option v-for="item in reviewResultList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </el-popover>
  <div class="black-hovers" @click="handleOverlayClick()" v-if="visible"></div>
</template>

<script>
import { ref } from 'vue';
import { useReviewResult } from '../utils/reviewResult';

export default {
  name: 'ReviewResult',
  emits: ['change', 'change-close'],
  setup(props, { emit }) {
    const visible = ref(false);

    const handleFilterOpen = () => {
      visible.value = true;
    };
    const { selectedResult, handleResultChange, reviewResultList } = useReviewResult(true);
    const handleOverlayClick = () => {
      emit('change-close', selectedResult.value);
      visible.value = false;
    };

    const handleChange = (val) => {
      handleResultChange(val);
      emit('change', val);
    };
    return {
      visible,
      handleOverlayClick,
      selectedResult,
      reviewResultList,
      handleChange,
      handleFilterOpen,
    };
  },
};
</script>
