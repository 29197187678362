<template>
  <el-dialog
    custom-class="create-form-dialog"
    v-model="visible"
    :title="dialogTitle"
    width="520px"
    @close="closeDialog"
    destroy-on-close
  >
    <el-steps :active="step" simple style="margin-bottom: 20px" v-if="!edit">
      <el-step title="基本设置"></el-step>
      <el-step title="高级设置"></el-step>
    </el-steps>
    <base-form
      v-show="step === 1"
      :visible="visible"
      :base-form="baseForm"
      :edit="edit"
      :env="env"
      @complete="handleComplete"
      @cancel="handleCancel"
      @success="handleSuccess"
    />
    <data-config
      v-if="visible && !edit"
      v-show="step === 2"
      :visible="visible"
      :base-form="baseForm"
      :env="env"
      @complete="handleComplete"
      @cancel="handleCancel"
      @change-step="handleChangeStep"
      @success="handleSuccess"
    />
  </el-dialog>
</template>
<script>
import { computed, defineComponent, reactive, ref } from 'vue';
import { ENV_ENUM } from '../../type';
import BaseForm from './Base.vue';
import DataConfig from './DataConfig.vue';
export default defineComponent({
  name: 'CreateFormDialog',
  components: {
    BaseForm,
    DataConfig,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    env: {
      type: String,
      default: ENV_ENUM.TEST,
    },
  },
  emits: ['success'],
  setup(props, { emit }) {
    const visible = ref(false);
    const step = ref(1);
    const baseForm = reactive({
      base: {
        name: props.env === ENV_ENUM.PROD ? 'prod' : '',
        description: props.env === ENV_ENUM.PROD ? '生产环境' : '',
        owner: '',
        owners: [],
        remark: '',
      },
      dbConfig: {
        source: props.env === ENV_ENUM.PROD ? 1 : 0,
        url: '',
        username: '',
        password: '',
        authType: localStorage.getItem('gatewayInfo'),
      },
    });
    const openDialog = (sourceData) => {
      if (sourceData) {
        baseForm.base = { ...sourceData };
      }
      visible.value = true;
    };

    const closeDialog = () => {
      visible.value = false;
      step.value = 1;
    };

    const handleComplete = ({ nextStep, currentStep, form }) => {
      step.value = nextStep;
      if (currentStep === 1) {
        baseForm.base = form;
      } else {
        baseForm.dbConfig = form;
      }
    };

    const handleCancel = () => {
      closeDialog();
    };

    const handleChangeStep = (changedStep) => {
      step.value = changedStep;
    };

    const handleSuccess = (payload) => {
      emit('success', payload);
    };

    const dialogTitle = computed(() => {
      const env = props.env === ENV_ENUM.PROD ? '生产' : '测试';
      if (props.edit) {
        return `编辑${env}环境`;
      }
      return `新建${env}环境`;
    });

    return {
      visible,
      step,
      baseForm,
      dialogTitle,
      openDialog,
      closeDialog,
      handleComplete,
      handleCancel,
      handleChangeStep,
      handleSuccess,
    };
  },
});
</script>
<style lang="scss">
.create-form-dialog {
  .form-btns {
    margin: 40px 0 0 0;
    text-align: center;
  }
}
</style>
