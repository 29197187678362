<template>
  <div class="env-detail__content">
    <div class="env-detail__content__head">
      <el-button type="primary" icon="el-icon-plus" @click="handleDialogOpen" v-if="getShowBool('add')">{{
        env === ENV_ENUM.TEST ? '引入' : '前往申请'
      }}</el-button>
    </div>
    <div class="env-detail__content__body">
      <app-list type="main" :create="handleDialogOpen" ref="refAppList" :env="env">
        <template #card="{ data: app }">
          <ApplicationCardVue
            :data="app"
            :env="env"
            :env-id="envId"
            :env-name="envData.name"
            :has-detail-auth="getShowBool('selectDetail')"
            :show-status="true"
            app-type="main"
            class="app__card"
          />
        </template>
      </app-list>
    </div>
    <import-dialog ref="refImportDialog" import-type="main-app" @complete="handleRefreshList" />
    <deploy-dialog ref="refDeployDialog" deploy-type="main-app" />
  </div>
</template>
<script>
import { ref } from 'vue';
import { userInfo } from '@/layout/messageCenter/user-info';
import { getShowBool } from '@/utils/permission-show-module';
import AppList from '../../application-module/components/app-list/Index.vue';
import { useEnvId } from './detail';
import ImportDialog from './components/ImportDialog';
import { ENV_ENUM } from '../type';
import ApplicationCardVue from '@/views/application-module/components/ApplicationCard.vue';
import { computeStatusLabel, statusColor } from '@/views/service-management/business-service/utils/service-status-map';
import DeployDialog from './components/DeployDialog';
export default {
  name: 'EnvDetailMainFe',
  components: {
    DeployDialog,
    AppList,
    ImportDialog,
    ApplicationCardVue,
  },
  props: {
    env: {
      type: String,
      default: ENV_ENUM.TEST,
    },
    envData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const envId = useEnvId();
    const refImportDialog = ref();
    const refDeployDialog = ref();
    const refAppList = ref();
    const handleDialogOpen = () => {
      if (props.env === ENV_ENUM.TEST) {
        refImportDialog.value.handleOpen();
      } else {
        refDeployDialog.value.handleOpen();
      }
    };
    const handleRefreshList = () => {
      refAppList.value.refresh();
    };
    return {
      envId,
      userInfo,
      getShowBool,
      handleDialogOpen,
      refImportDialog,
      refDeployDialog,
      refAppList,
      handleRefreshList,
      computeStatusLabel,
      statusColor,
      ENV_ENUM,
    };
  },
};
</script>
