
import { defineComponent, watch, ref, Ref, onMounted, getCurrentInstance, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { getModelNames, getDataChangesNewForPublish, dataSelect, dataDeSelect } from '@/api/servers';

const defaultProps: any = {
  label: 'name',
  id: 'id',
};

// 数据row改变状态
enum CHANGE_ROW_STATUS {
  'not',
  'add',
  'del',
  'modify',
}

// 数据cell改变状态
enum CHANGE_CELL_STATUS {
  'cell_not',
  'cell_add',
  'cell_del',
  'cell_modify',
}
// 错误码
enum ERROR_CODE {
  'SUC',
  'PRE' = 1114000,
}
// 轮询时间
const INTERVAL_TIME = 1000;
const PAGE_SIZE = 100;
export default defineComponent({
  name: 'DataBaseChangeInfo',
  props: {
    lastVersion: {
      type: String,
      default: '',
    },
    currentVersion: {
      type: String,
      default: '',
    },
    propsServiceId: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const filterText: Ref<string> = ref('');
    const tableTreeRef: any = ref(null);
    const tableRef: any = ref(null);
    const isLastPage: Ref<boolean> = ref(false);
    // 累计勾选
    const checkIds: any = ref([]);
    // 当前页
    const currentPage: Ref<number> = ref(1);
    // 获取路由信息
    const route = useRoute();
    // 当前服务ID
    const serviceId = ref(Number(route.params.id ? route.params.id : props.propsServiceId));
    const modelId = ref(0);
    const tableTreeData: any = ref([]);
    const tableData: any = ref([]);
    const isOnlyShowChange: Ref<boolean> = ref(false);
    const tableLoading: Ref<boolean> = ref(false);
    const modelCheckAllCount: any = ref(0);
    let tableAllData: any = [];
    let tableChangeData: any = [];
    let tablePaginationLastId: any = [0];
    let isPageChange = false;
    let timer: any = null;
    const modelCheckData: any = ref({});
    const currentSelModel: any = ref({});

    // 获取组件实例
    const instance = getCurrentInstance();
    // 提示信息
    function msgTips(type: string, content: string) {
      (instance as any).proxy.$message({
        type,
        message: content,
      });
    }
    const init = () => {
      tableAllData = [];
      tableChangeData = [];
      tablePaginationLastId = [0];
      tableData.value = [];
      currentPage.value = 1;
      modelCheckAllCount.value = 0;
    };

    // 获取模型树数据
    const getTreeData = async () => {
      try {
        // 待修改ser
        const { code, data } = await getModelNames(serviceId.value);
        if (code === ERROR_CODE.SUC) {
          const { modelNamePairs } = data;
          tableTreeData.value = modelNamePairs.map((item: any) => ({
            ...item,
            count: 0,
          }));
          // 组装模型选中的数据
          const modelCheckCount: any = {};
          modelNamePairs.forEach((item: any) => {
            modelCheckCount[item.id] = [];
          });
          modelCheckData.value = modelCheckCount;
        }
      } catch (error) {}
    };
    // 历史数据
    const historyTableColumn: any = ref([]);
    // 当前数据
    const currentTableColumn: any = ref([]);

    // 表名过滤
    function filterNode(value: string, data: any) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }

    // 过滤
    watch(filterText, (newValue: string) => {
      tableTreeRef.value.filter(newValue);
    });

    // 只显示变更数据
    watch(isOnlyShowChange, (newValue: boolean) => {
      if (newValue) {
        tableData.value = tableChangeData;
      } else {
        tableData.value = tableAllData;
      }
    });
    const getModelCheckAllCount = () => {
      let sum = 0;
      modelCheckData.value[currentSelModel.value.id].forEach((item: any) => {
        sum = sum + item.length;
      });
      modelCheckAllCount.value = sum;
      tableTreeData.value = tableTreeData.value.map((item: any) => {
        if (currentSelModel.value.id === item.id) {
          return { ...item, count: sum };
        }
        return { ...item };
      });
    };
    // 处理diff数据
    const handleDiffData = (data: any) => {
      isPageChange = true;
      const { columnNames, diffItems } = data;
      // 当前column
      currentTableColumn.value = columnNames;
      // 历史的column
      historyTableColumn.value = columnNames.map((item: string) => `${item}Pre`);
      // 是否是最后一页
      isLastPage.value = !(diffItems.length < PAGE_SIZE);
      // tableData数据处理
      const tableOriginData: any = diffItems.map((item: any) => {
        const { uniqueId, source = {}, target = {}, changeType, selected } = item;
        const historyData: any = {};
        Object.keys(source || {}).forEach((hisItem: any) => {
          historyData[`${hisItem}Pre`] = source[hisItem];
        });
        // 如果是修改的话需要返回修改项
        const changeColumns: any = [];
        if (changeType === CHANGE_ROW_STATUS.modify) {
          Object.keys(target).forEach((item) => {
            if (source[item] !== target[item]) {
              changeColumns.push(item);
            }
          });
        }
        return {
          ...historyData,
          ...target,
          uniqueId,
          changeType,
          changeColumns,
          source,
          target,
          selected,
        };
      });
      let count = 0;
      // 是否出现连续的未更改
      const newData: any = [];
      const changeData: any[] = [];
      const checkKey: number[] = [];
      tableOriginData.forEach((item: any) => {
        const { changeType, selected } = item;
        if (changeType === CHANGE_ROW_STATUS.not) {
          count = count + 1;
          // 数据折叠
          if (count >= 2) {
            const len = newData.length;
            if (newData[len - 1].children) {
              newData[len - 1].children.push(item);
            } else {
              newData[len - 1].children = [item];
            }
          } else {
            newData.push(item);
          }
        } else {
          changeData.push(item);
          newData.push(item);
          count = 0;
          if (selected) {
            checkKey.push(item);
          }
        }
      });
      // 当前页数据 变更 or 所有
      tableChangeData = changeData;
      tableAllData = newData;
      // 下一页的起始值是否存在
      if (!tablePaginationLastId[currentPage.value]) {
        // 获取分页的起始
        const lastItem = newData.slice(-1)[0];
        const lastChildItem = (lastItem.children && lastItem.children.slice(-1)[0]) || {};
        tablePaginationLastId.push(lastChildItem.uniqueId || lastItem.uniqueId);
      }
      if (isOnlyShowChange.value) {
        tableData.value = changeData;
      } else {
        tableData.value = newData;
      }
      // 数据漫游
      modelCheckData.value[currentSelModel.value.id][currentPage.value] = [];
      checkKey.forEach((item: any) => {
        const res = tableData.value.find((subItem: any) => subItem.uniqueId === item.uniqueId);
        if (res) {
          modelCheckData.value[currentSelModel.value.id][currentPage.value].push(res);
          tableRef.value.toggleRowSelection(res, true);
        }
      });
      getModelCheckAllCount();
      // 关闭加载中
      tableLoading.value = false;
      isPageChange = false;
    };

    // 获取当前模型的对比数据
    const getDiffDataNew = async (startId: any) => {
      try {
        const { code, data, message } = await getDataChangesNewForPublish({
          modelId: modelId.value,
          serviceId: serviceId.value,
          startId,
          pageSize: PAGE_SIZE,
          asc: false,
        });
        if (code === ERROR_CODE.SUC) {
          const { ready } = data;
          if (!ready) {
            timer && clearTimeout(timer);
            // 间隔1s继续查
            timer = setTimeout(() => {
              getDiffDataNew(startId);
            }, INTERVAL_TIME);
          } else {
            handleDiffData(data);
          }
        } else {
          tableLoading.value = false;
          // 弹出错误信息
          msgTips('error', message);
        }
      } catch (error) {
        tableLoading.value = false;
      }
    };

    // 树节点点击
    const handleNodeClick = (data: any) => {
      console.log('data', data);
      currentSelModel.value = data;
      // 初始化
      init();
      tableLoading.value = true;
      modelId.value = data.id;
      // 获取
      getDiffDataNew(0);
    };

    // 行的样式
    const rowClassName = (data: { row: any }) => {
      const { row } = data;
      return CHANGE_ROW_STATUS[row.changeType];
    };

    // 单元格的样式
    const cellClassName = (data: { row: any; column: any }) => {
      const { row, column } = data;
      // 是否修改
      if (CHANGE_ROW_STATUS.modify === row.changeType) {
        // 当前列
        const { property } = column;
        // 修改列
        if (row?.changeColumns?.includes(property)) {
          return CHANGE_CELL_STATUS[row.changeType];
        }
      }
      return CHANGE_CELL_STATUS[0];
    };

    // 全选
    const handleSelectionAll = async (val: any) => {
      const reqData: any = {
        modelId: modelId.value,
        serviceId: serviceId.value,
      };
      let uniqueIds: any = [];
      // 全取消
      if (!val.length) {
        uniqueIds = modelCheckData.value[currentSelModel.value.id][currentPage.value].map(
          (subItem: any) => subItem.uniqueId,
        );
        uniqueIds.length && (await dataDeSelect({ ...reqData, uniqueIds }));
      } else {
        // 全选
        val.forEach((subItem: any) => {
          if (
            !modelCheckData.value[currentSelModel.value.id][currentPage.value].find(
              (item: any) => item.uniqueId === subItem.uniqueId,
            )
          ) {
            uniqueIds.push(subItem.uniqueId);
          }
        });
        uniqueIds.length && (await dataSelect({ ...reqData, uniqueIds }));
      }
      modelCheckData.value[currentSelModel.value.id][currentPage.value] = [...val];
      getModelCheckAllCount();
    };

    // 变更
    const handleSelection = (val: any, row: any) => {
      debugger;
      if (isPageChange) return;
      // 翻页不触发
      const reqData = {
        modelId: modelId.value,
        serviceId: serviceId.value,
      };
      modelCheckData.value[currentSelModel.value.id][currentPage.value] = val;
      getModelCheckAllCount();
      if (val.length) {
        dataSelect({ ...reqData, uniqueIds: [row.uniqueId] });
      } else {
        dataDeSelect({ ...reqData, uniqueIds: [row.uniqueId] });
      }
    };

    // 是否可选
    const handleSel = (row: any) => {
      const { changeType } = row;
      if (changeType) {
        return true;
      }
      return false;
    };

    onMounted(async () => {
      await getTreeData();
    });

    onBeforeUnmount(() => {
      timer && clearTimeout(timer);
      timer = null;
    });
    // 获取diff选中数据
    const getDataChangeReq = () => modelCheckData.value;

    // 下一页
    const handleNext = () => {
      currentPage.value = currentPage.value + 1;
      // 获取当前页最后一条数据的id
      const lastItem = tableAllData.slice(-1)[0];
      const lastChildItem = (lastItem.children && lastItem.children.slice(-1)[0]) || {};
      getDiffDataNew(lastChildItem.uniqueId || lastItem.uniqueId);
    };

    // 上一页
    const handlePrev = () => {
      currentPage.value = currentPage.value - 1;
      // 从本地取数据
      getDiffDataNew(tablePaginationLastId[currentPage.value - 1]);
    };
    return {
      defaultProps,
      tableTreeData,
      tableData,
      historyTableColumn,
      currentTableColumn,
      tableTreeRef,
      filterText,
      filterNode,
      rowClassName,
      handleSelection,
      handleNodeClick,
      handleSel,
      cellClassName,
      getDataChangeReq,
      tableRef,
      isOnlyShowChange,
      tableLoading,
      getDiffDataNew,
      checkIds,
      currentPage,
      handleNext,
      handlePrev,
      modelId,
      isLastPage,
      modelCheckAllCount,
      handleSelectionAll,
      currentSelModel,
    };
  },
});
