export const MODULE_TYPE: any = {
  1: '服务',
  2: '应用',
};

export const AUDIT_RESULTS: any = {
  0: '未审核',
  1: '通过',
  2: '未通过',
};

export const AUDIT_RESULTS_CODE: any = {
  UNREVIEWED: 0,
  PASSED: 1,
  FAILED: 2,
  DEPLOYED: 3,
};

export const STATUS: any = {
  0: '未审核',
  1: '审核通过',
  2: '审核不通过',
  3: '已部署',
};

export const getModuleType = (type: number) => MODULE_TYPE[type];

export const changeUsers = (data = []) =>
  data.map((item: any) => ({
    id: item.id,
    name: item.displayName,
  }));
