import { ref } from 'vue';
import { changeUsers } from '@/views/demands/publish/constant';
import { getApplyOrAuditUser } from '@/api/env';

// 双向绑定选中的值
const selectedReviewer = ref();

// select onChange事件
const handleReviewerChange = (val: Array<string>) => {
  selectedReviewer.value = val;
};

// 申请人下拉列表
const reviewerList = ref();

// 获取申请人列表
const fetchList = async () => {
  const { data } = await getApplyOrAuditUser();
  reviewerList.value = changeUsers(data.reviewers);
};

export const useReviewer = (initList = false) => {
  if (initList) {
    fetchList();
  }
  return {
    selectedReviewer,
    handleReviewerChange,
    reviewerList,
  };
};
