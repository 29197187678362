
// @ts-nocheck
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { defineComponent, ref, PropType, Ref, computed, SetupContext, getCurrentInstance, watch } from 'vue';
import { IMAGE_UPLOAD } from '@/shared/constant/file';
// import ApplicationDetail from './ApplicationDetail.vue';
import { SuccessResponse } from '@/types/response';
import { updateAppById, deleteAppById } from '@/api/app';
import { getShowBool } from '@/utils/permission-show-module';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { getServiceShowName, getTemplateTIcon } from '@/views/application-module/utils/utils';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { App, AppType } from '../type';
import { ENV_ENUM, ENV_TYPE } from '../../env/type';
import { statusColor, computeStatusLabel } from '@/views/service-management/business-service/utils/service-status-map';
import Version from '@/views/application-module/components/upgrade-instance/Version.vue';
import DeleteDialog from '@/views/service-management/business-service/components/DeleteServiceDialog.vue';
import { delSameResourcesFromEnv } from '@/api/project/project';
export default defineComponent({
  name: 'ApplicationCard',
  components: { ServiceName, Version, DeleteDialog },
  props: {
    data: {
      type: Object as PropType<App>,
      default: () => ({}),
    },
    env: {
      type: String as PropType<ENV_TYPE>,
      default: 'DEV',
    },
    appType: {
      type: String as PropType<AppType>,
      default: 'main',
    },
    envId: {
      type: [String, Number],
      default: null,
    },
    envName: {
      type: String,
      default: '',
    },
    hasDetailAuth: {
      type: Boolean,
      default: true,
    },
    // 跳转path处理太繁琐了，提供一个onClick回调，各业务端自由处理
    onClick: {
      type: Function,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update'],
  setup(props, ctx: SetupContext) {
    const isDetailVisible: Ref<boolean> = ref(false);
    const detailInfo: Ref<App> = ref(props.data);
    const instance = getCurrentInstance();
    const uploadUrl = ref('');
    const deleteServiceDialogRef = ref();

    const imageUrl = computed(() => detailInfo.value.thumbnail || uploadUrl.value);
    watch(
      () => props.data,
      (value: App) => {
        detailInfo.value = value;
      },
    );

    const computedDetail = computed(() => ({ ...detailInfo.value, imageUrl }));

    const onCloseDetail = (type?: string) => {
      isDetailVisible.value = false;
      if (type === 'cancel') {
        return;
      }
      ctx.emit('update');
    };

    const handleDeleteForEnvItem = async () => {
      ElMessageBox.confirm(`是否删除该应用？`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { code } = await deleteAppById(detailInfo.value.id);
        ctx.emit('update');
        if (code === 0) {
          (instance as any).proxy.$message({
            type: 'success',
            message: '应用删除成功！',
          });
        } else {
          (instance as any).proxy.$message({
            type: 'error',
            message: '应用删除失败！',
          });
        }
      });
    };

    const appTypeLabel = computed(() => (props.appType === 'main' ? '主应用' : '微前端模块'));

    const handleDelete = () => {
      // 非Dev环境删除直接确认就行
      if (props.env !== ENV_ENUM.DEV) {
        return handleDeleteForEnvItem();
      }
      // 开发环境删除需要同步
      deleteServiceDialogRef.value.openDialog(
        `确定删除${appTypeLabel.value}“${props.data.name}”吗？此操作不可逆。`,
        async (sync: boolean) => {
          await deleteAppById(props.data.id);
          ctx.emit('update');
          ElMessage.success('删除命令下发成功');
          if (sync) {
            delSameResourcesFromEnv({
              serviceType: 'app',
              serviceName: props.data.name,
              devProjectId: props.data.projectId,
              env: 'TEST',
            });
          }
        },
      );
    };

    const beforeUpload = (file: { size: number }) => {
      if (file.size > 1024 ** 2 * 3) {
        (instance as any).proxy.$message({
          type: 'warning',
          message: '上传图片大小不能超过3M',
        });
        return false;
      }
    };

    const logoUploadError = () => {
      (instance as any).proxy.$message({
        type: 'error',
        message: '上传失败，请重新上传！',
      });
    };

    const logoUploadSuccess = async (res: SuccessResponse<any>, file: { raw: any }) => {
      if (res.code === 0 && res.data?.fileKey) {
        detailInfo.value.thumbnail = res.data.fileKey;
        uploadUrl.value = URL.createObjectURL(file.raw);
        updateAppById(detailInfo.value.id, detailInfo.value);
      } else {
        logoUploadError();
      }
    };

    const router = useRouter();
    const route = useRoute();
    const jump2AppDetail = () => {
      if (props.data.delState > 0) {
        return;
      }
      if (!props.hasDetailAuth) {
        return ElMessage.error('暂无查看详情权限！');
      }
      if (props.onClick && typeof props.onClick === 'function') {
        // @ts-ignore
        return props.onClick({
          id: props.data.id,
          env: props.env,
          envId: props.envId,
          router,
        });
      }
      if (props.env && props.env !== ENV_ENUM.DEV) {
        router.push({
          path: `/env/${props.env.toLowerCase()}/${props.envId}/main-app/${props.data.id}`,
          query: {
            env: props.env,
            envName: props.envName,
            pp: route.query.page,
          },
        });
        return;
      }
      if (props.appType === 'main') {
        router.push({
          path: `/apps/main/${props.data.id}`,
          query: {
            pp: route.query.page,
          },
        });
      } else {
        router.push({
          path: `/apps/micro/${props.data.id}`,
          query: {
            pp: route.query.page,
          },
        });
      }
    };
    return {
      IMAGE_UPLOAD,
      isDetailVisible,
      detailInfo,
      imageUrl,
      computedDetail,
      onCloseDetail,
      // onClose,
      beforeUpload,
      logoUploadSuccess,
      logoUploadError,
      getShowBool,
      jump2AppDetail,
      getServiceShowName,
      statusColor,
      computeStatusLabel,
      getTemplateTIcon,
      deleteServiceDialogRef,
      handleDelete,
      appTypeLabel,
    };
  },
});
