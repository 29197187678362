import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75602434"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list__container" }
const _hoisted_2 = { class: "list-item__cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApplicationCard = _resolveComponent("ApplicationCard")!
  const _component_packaged_pagination = _resolveComponent("packaged-pagination")!
  const _component_list_wrap = _resolveComponent("list-wrap")!

  return (_openBlock(), _createBlock(_component_list_wrap, {
    loading: _ctx.loading,
    empty: _ctx.empty,
    handleCreate: 
      () => {
        _ctx.create?.();
      }
    ,
    hasCreateAuth: _ctx.getShowBool('add'),
    "create-label": _ctx.dynamicLabel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
            return _renderSlot(_ctx.$slots, "card", {
              key: item.id,
              data: item
            }, () => [
              _createVNode(_component_ApplicationCard, {
                data: item,
                env: _ctx.env,
                "env-id": _ctx.envId,
                "env-name": _ctx.envName,
                appType: _ctx.appType,
                "has-detail-auth": _ctx.getShowBool('selectDetail'),
                onUpdate: _ctx.refresh
              }, null, 8, ["data", "env", "env-id", "env-name", "appType", "has-detail-auth", "onUpdate"])
            ], true)
          }), 128))
        ]),
        (_ctx.list.length && !_ctx.loading)
          ? (_openBlock(), _createBlock(_component_packaged_pagination, {
              key: 0,
              "current-page": _ctx.query.page,
              "page-size": _ctx.query.pageSize,
              total: _ctx.total,
              onSizeChange: _ctx.handlePageSizeChange,
              onCurrentChange: _ctx.handlePageChange
            }, null, 8, ["current-page", "page-size", "total", "onSizeChange", "onCurrentChange"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["loading", "empty", "handleCreate", "hasCreateAuth", "create-label"]))
}