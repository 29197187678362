<template>
  <i class="el-icon-search"></i>
  <el-popover placement="bottom" :width="200" trigger="manual" :visible="visible">
    <template #reference>
      <el-button type="text" @click="handleFilterOpen">申请人</el-button>
    </template>
    <el-select v-model="selectedApplicant" placeholder="请选择申请人" clearable multiple @change="handleChange">
      <el-option v-for="item in applicantList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </el-popover>
  <div class="black-hovers" @click="handleOverlayClick()" v-if="visible"></div>
</template>

<script>
import { ref } from 'vue';
import { useApplicant } from '../utils/applicant';

export default {
  name: 'Applicant',
  emits: ['change', 'change-close'],
  setup(props, { emit }) {
    // 打开popover相关
    const visible = ref(false);
    const handleFilterOpen = () => {
      visible.value = true;
    };

    const { selectedApplicant, handleApplicantChange, applicantList } = useApplicant(true);

    const handleChange = (val) => {
      handleApplicantChange(val);
      emit('change', val);
    };

    const handleOverlayClick = () => {
      emit('change-close', selectedApplicant.value);
      visible.value = false;
    };

    return {
      visible,
      handleFilterOpen,
      handleOverlayClick,
      selectedApplicant,
      applicantList,
      handleChange,
    };
  },
};
</script>
