<template>
  <div class="services-merge-list">
    <el-row v-if="!env">
      <el-col :span="6" style="text-align: left">
        <el-button type="primary" @click="handleDialogOpen()"> 合并部署 </el-button>
      </el-col>
      <el-col :offset="12" :span="6" style="text-align: right">
        <el-input
          placeholder="请输入合并包中文/英文名"
          suffix-icon="el-icon-search"
          v-model="searchProps.keyword"
          @input="filterSpaceService"
        ></el-input>
      </el-col>
    </el-row>
    <div class="merge-list">
      <list-wrap
        :loading="tableState.loading"
        :empty="tableState.tableData.length === 0"
        :useSelectAuth="false"
        :handleCreate="handleDialogOpen"
        :createLabel="createLabel"
      >
        <el-table :data="tableState.tableData" style="width: 100%">
          <el-table-column type="index" width="100" label="序号"></el-table-column>
          <el-table-column label="合并部署包英文名" prop="name">
            <template #default="scope">
              <el-tooltip effect="dark" :visible-arrow="false" :content="scope.row.name" placement="right">
                <span :title="scope.row.name" class="detail" @click="handleDetailOpen(scope.row)">
                  {{ getServiceShowName(scope.row.name) }}</span
                >
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="合并部署包中文名" prop="description"></el-table-column>
          <el-table-column label="描述" prop="detail"></el-table-column>
          <el-table-column label="负责人" prop="ownerString"></el-table-column>
          <el-table-column label="状态">
            <template #default="scope">
              <template v-if="scope.row.delState > 0">
                <span v-if="scope.row.delState === 1" class="sa-status-text sa-text-progress">删除中</span>
                <span v-if="scope.row.delState === 3" class="sa-status-text sa-text-error">删除异常</span>
              </template>
              <template v-else>
                <span class="service-list-borders" :style="{ background: statusColor[scope.row.status] }"></span>
                <span :style="{ color: statusColor[scope.row.status] }">{{
                  computeStatusLabel(scope.row.initTimes)[scope.row.status]
                }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="最新版本" prop="serviceVersion">
            <template #default="scope">
              <template v-if="env">
                <Version type="service" :data="scope.row" class="service-version__container" alowUpgrade></Version>
              </template>
              <template v-else>
                <a :href="sshHost + scope.row.deposit" target="_blank"
                  ><svg-icon icon-name="coding" icon-class="detail-icons__item"></svg-icon>&nbsp;{{
                    scope.row.serviceVersion
                  }}</a
                >
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300">
            <template #default="scope">
              <el-button
                type="text"
                @click="handleInit(scope.row)"
                :disabled="(env.length > 0 && scope.row.initTimes) || isDisabled('init', scope.row.status)"
                >{{ scope.row.initTimes ? '同步配置' : '初始化' }}</el-button
              >
              <el-button
                type="text"
                @click="handleDialogOpen(scope.row)"
                :disabled="isDisabled('edit', scope.row.status)"
                v-if="!env"
                >编辑</el-button
              >
              <el-button
                type="text"
                :disabled="isDisabled('start', scope.row.status)"
                @click="handleStart(scope.row.id)"
                >启动</el-button
              >
              <el-button type="text" :disabled="isDisabled('stop', scope.row.status)" @click="handleStop(scope.row.id)"
                >停止</el-button
              >
              <el-button type="text" @click="handleLog(scope.row.id)">日志</el-button>
              <el-button
                type="text"
                :disabled="isDisabled('publish', scope.row.status)"
                @click="handlePublish(scope.row.id)"
                v-if="!env"
                v-loading="publishLoading[scope.row.id]"
                >发版</el-button
              >
              <el-button
                type="text"
                :disabled="scope.row.delState === 1 || isDisabled('del', scope.row.status)"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <packaged-pagination
          v-if="tableState.tableData.length && !tableState.loading"
          :current-page="searchProps.page"
          :page-size="searchProps.pageSize"
          :total="tableState.total"
          @size-change="handlePageSizeChange"
          @current-change="handlePageChange"
        ></packaged-pagination>
      </list-wrap>
    </div>
    <LogDialog :serviceId="chooseService" service-type="BACKEND" ref="logDialogRef" />
    <domain-config ref="domainRef" service-type="BACKEND" :serviceId="chooseService" :on-start="handleConfirmStart" />
    <merge-deploy-dialog ref="addDialog" @successAdd="handleRefresh" />
    <merge-service-detail ref="detailDialog" />
    <ReleaseDialog ref="releaseRef" />
    <delete-service-dialog ref="deleteServiceDialogRef" service-type="service" />
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, onBeforeUnmount, reactive, ref ,provide} from "vue";
import {
  deleteService,
  getPreDataStart,
  getServiceList,
  releaseCheck,
  startService,
  updateServiceStatus
} from '@/api/servers';
import { statusColor, computeStatusLabel } from '@/views/service-management/business-service/utils/service-status-map';
import { getServiceShowName } from '@/views/service-management/components/utils';
import LogDialog from '@/views/service-management/business-service/components/LogDialog';
import { ElMessageBox } from 'element-plus';
import {
  checkBeforeStart,
  handleChangeApply,
  stopServiceData,
} from "@/views/service-management/business-service/utils/service-detail-data";
import { DeploymentMethod } from "@/views/conf-management/types";
import { openDomainDialog } from "@/views/service-management/business-service/components/domainConfig/dataCenter";
import useTenant from "@/views/tenant-management/useTenant";
import DomainConfig from "@/views/service-management/business-service/components/domainConfig/DomainConfig";
import MergeDeployDialog from "@/views/services-merge-deploy/components/MergeDeployDialog";
import MergeServiceDetail from "@/views/services-merge-deploy/components/MergeServiceDetail";
import ReleaseDialog from "@/views/service-management/business-service/components/ReleaseDialog";
import DeleteServiceDialog from "@/views/service-management/business-service/components/DeleteServiceDialog";
import Message from "element-plus/es/el-message";
import { delSameResourcesFromEnv } from "@/api/project/project";
import _ from "lodash";
import Version from "@/views/application-module/components/upgrade-instance/Version";
import { isProxyService } from '@/views/service-management/business-service/useAddProxy';

export default defineComponent({
  name: "ServicesMergeList",
  components: {
    MergeServiceDetail,
    MergeDeployDialog,
    LogDialog,
    DomainConfig,
    ReleaseDialog,
    DeleteServiceDialog,
    Version,
  },
  props: {
    env: {
      type: String,
      default: () => "",
    },
    handleCreate: {
      type: Function,
      default: _.noop,
    },
    createLabel: {
      type: String,
      default: "新建",
    },
  },
  setup() {
    const sshHost = ref("");
    const tableState = reactive({
      tableData: [],
      total: 0,
      loading: false,
    });
    const searchProps = reactive({
      page: 1,
      pageSize: 10,
      keyword: "",
    });

    const { tenant, fetchTenantInfo } = useTenant();
    if (!tenant) {
      fetchTenantInfo();
    }

    const chooseService = ref(0);
    const logDialogRef = ref(null);
    const domainRef = ref(null);
    const releaseRef = ref(null);
    const deleteServiceDialogRef = ref(null);

    const handleLog = (id) => {
      chooseService.value = id;
      logDialogRef.value.handleOpenDialog();
    };

    const refreshList = async (openLoading = false) => {
      if (openLoading) {
        tableState.loading = true;
      }
      try {
        const { data } = await getServiceList({
          ...searchProps,
          appearance: 4,
        });
        const ownerMap = {};
        data.ownerUsers.forEach((x) => {
          ownerMap[x.id] = x.displayName;
        });
        data.rows.forEach((x) => {
          const ownerArr = [
            ...new Set(x.owners.map((x) => ownerMap[x.userId])),
          ];
          x.ownerString = ownerArr.length ? ownerArr.join("，") : "";
        });
        tableState.tableData = data.rows;
        tableState.total = data.count;
        tableState.loading = false;
        const stopRows = data.rows.filter((x) => x.status === 30);
        if (stopRows.length) {
          const ids = [];
          stopRows.forEach((item) => {
            ids.push(item.id);
          });
          updateServiceStatus(ids);
        }
        sshHost.value = data.sshHost;
      } catch (e) {
        console.log(e);
        tableState.loading = false;
      }
    };
    refreshList(true);

    const handlePageSizeChange = (pageSize) => {
      searchProps.pageSize = pageSize;
      refreshList(true);
    };

    const handlePageChange = (pageNo) => {
      searchProps.page = pageNo;
      refreshList(true);
    };

    const handleInit = (row) => {
      chooseService.value = row.id;
      const name = row.status ? "同步配置" : "初始化";
      ElMessageBox.confirm(`确定${name}此服务, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          await handleChangeApply(row.id);
          handleLog(row.id);
        } catch (error) {
          console.log(error);
        }
      });
    };

    const handleStart = async (id) => {
      chooseService.value = id;
      const { code, data } = await checkBeforeStart(id);
      const message =
        code === 0 && data === "ok" ? "请确认此服务已初始化, 是否继续?" : data;
      ElMessageBox.confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (
          tenant.value?.deploymentMethod === DeploymentMethod.VitrualMachine
        ) {
          return domainRef.value?.handleStartWithoutDomainInfo();
        }
        openDomainDialog();
      });
    };
    const handleConfirmStart = async (domainData) => {
      const res = await startService({
        serviceId: chooseService.value,
        branch: "master",
        userId: "1",
        ...domainData,
      });
      if (res.data) {
        handleLog(chooseService.value);
      }
    };

    const handleStop = (id) => {
      ElMessageBox.confirm(`请确认停止此服务, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => stopServiceData(id));
    };
    provide(isProxyService, ref(true));
    const publishLoading = ref({});
    const handlePublish = async (id) => {
      publishLoading.value[id] = true;
      try {
        const { code } = await releaseCheck(id);
        if (code === 0) {
          // 数据准备开始
          const { code } = await getPreDataStart(id);
          if (code === 0) {
            releaseRef.value.openDialog(id);
          }
          publishLoading.value[id] = false;
        }
      } catch (e) {
        publishLoading.value[id] = false;
      }
    };

    const handleDelete = (row) => {
      console.log(deleteServiceDialogRef.value);
      deleteServiceDialogRef.value.openDialog(
        `确定删除服务“${row.name}”吗？此操作不可逆。`,
        async (sync) => {
          await deleteService([row.id]);
          Message.success("删除命令下发成功");
          if (sync) {
            delSameResourcesFromEnv({
              serviceType: "service",
              serviceName: row.name,
              devProjectId: row.projectId,
              env: "TEST",
            });
          }
        }
      );
    };
    const isDisabled = (type, status) => {
      const mustDisabled = [10, 20, 30];
      const computeDisabled = {
        edit: [[10], []],
        del: [[10], []],
        init: [[10], []],
        start: [[0], []],
        stop: [[0], [21]],
        publish: [[0], [21]],
      };
      if (computeDisabled[type][1].length) {
        return !computeDisabled[type][1].includes(status);
      }
      return (
        mustDisabled.includes(status) ||
        computeDisabled[type][0].includes(status)
      );
    };

    const addDialog = ref();
    const handleDialogOpen = (row) => {
      addDialog.value.handleOpen(row);
    };

    const detailDialog = ref();
    const handleDetailOpen = (row) => {
      detailDialog.value.handleOpen(row);
    };

    const handleRefresh = () => {
      refreshList();
    };

    const timer = ref(null);
    timer.value = setInterval(() => {
      refreshList();
    }, 5 * 1000);

    onBeforeUnmount(() => {
      clearInterval(timer.value);
      timer.value = null;
    });

    // 输入框模糊查询列表
    const filterSpaceService = _.debounce(() => {
      searchProps.pageNo = 1;
      refreshList();
    }, 500);
    return {
      searchProps,
      tableState,
      statusColor,
      computeStatusLabel,
      handleInit,
      isDisabled,
      handlePageSizeChange,
      handlePageChange,
      chooseService,
      logDialogRef,
      handleLog,
      domainRef,
      handleStart,
      handleConfirmStart,
      addDialog,
      handleDialogOpen,
      detailDialog,
      handleDetailOpen,
      getServiceShowName,
      handleStop,
      releaseRef,
      publishLoading,
      handlePublish,
      handleDelete,
      handleRefresh,
      deleteServiceDialogRef,
      filterSpaceService,
      sshHost,
    };
  },
});
</script>

<style lang="scss" scoped>
.services-merge-list {
  width: 100%;
  .detail {
    color: #006eff;
    cursor: pointer;
  }
  ::v-deep .sa-list-wrap .el-loading-spinner .circular {
    height: 46px;
  }
  ::v-deep .el-button--text:focus {
    color: #006eff;
  }
}
</style>
