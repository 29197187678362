<template>
  <div class="env-detail detail-with-tabs">
    <el-tabs v-model="activeTab" @tab-click="handleTabClick">
      <!-- <el-tab-pane label="网关" name="gateway"><env-detail-gateway /> </el-tab-pane> -->
      <el-tab-pane label="主应用" name="main-app"
        ><env-detail-main-fe :env="env" :env-data="baseData" v-if="renderTabContent('main-app')" />
      </el-tab-pane>
      <el-tab-pane label="前端模块" name="micro-app"
        ><env-detail-micro-fe :env="env" :env-data="baseData" v-if="renderTabContent('micro-app')" />
      </el-tab-pane>
      <el-tab-pane label="服务" name="service">
        <env-detail-service :env="env" :env-data="baseData" v-if="renderTabContent('service')" />
      </el-tab-pane>
      <el-tab-pane label="合并部署" name="merge-service">
        <merge-service :env="env" :env-data="baseData" v-if="renderTabContent('merge-service')" />
      </el-tab-pane>
      <el-tab-pane label="详情" name="base" v-loading="loading">
        <env-detail-base :env="env" :env-data="baseData" :fetchDetail="fetchDetail" v-if="renderTabContent('base')" />
      </el-tab-pane>
      <el-tab-pane label="部署申请" name="deploy-apply" v-if="isProd">
        <env-detail-deploy-apply
          :env="env"
          :env-data="baseData"
          v-if="renderTabContent('deploy-apply')"
        ></env-detail-deploy-apply>
      </el-tab-pane>
      <el-tab-pane label="部署审核" name="deploy-review" v-if="isProd">
        <env-detail-deploy-review
          :env="env"
          :env-data="baseData"
          v-if="renderTabContent('deploy-review')"
        ></env-detail-deploy-review>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue';
import EnvDetailBase from './Base.vue';
// import EnvDetailGateway from './Gateway.vue';
import EnvDetailMainFe from './MainFe.vue';
import EnvDetailMicroFe from './MicroFe.vue';
import EnvDetailService from './Service.vue';
import { removeEnvStorage } from '@/utils/env-storage';
import { ENV_ENUM } from '../type';
import { useEnvId } from './detail';
import { useRoute, useRouter } from 'vue-router';
import { getProjectDetail } from '@/api/project/project';
import EnvDetailDeployApply from './DeployApply';
import EnvDetailDeployReview from './DeployReview';
import { ElMessage } from 'element-plus';
import MergeService from '@/views/env/detail/MergeService';
export default {
  name: 'EnvDetail',
  components: {
    EnvDetailDeployReview,
    EnvDetailDeployApply,
    EnvDetailBase,
    // EnvDetailGateway,
    EnvDetailMainFe,
    EnvDetailMicroFe,
    EnvDetailService,
    MergeService,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    ElMessage.closeAll();
    useEnvId();
    const route = useRoute();
    const router = useRouter();
    const activeTab = ref(route.query.tab || 'main-app');
    const loading = ref(false);
    const baseData = ref();
    const tabClicked = reactive({
      [activeTab.value]: true,
    });
    router.replace({
      query: {},
    });

    const env = ref(ENV_ENUM.TEST);

    // 获取数据
    const fetchDetail = async () => {
      loading.value = true;
      try {
        const { data: resData } = await getProjectDetail(route.params.id, {
          env: env.value,
        });
        baseData.value = resData;
        localStorage.setItem('gatewayenvInfos', JSON.stringify(resData.gatewayInfo));
        console.log(baseData.value, 'this is base data');
      } catch (e) {
        setTimeout(() => {
          router.go(-1);
        }, 2000);
      }
      loading.value = false;
    };

    const updateEnv = () => {
      env.value = route.name === 'EnvProdDetail' ? ENV_ENUM.PROD : ENV_ENUM.TEST;
    };
    updateEnv();
    fetchDetail();
    const isProd = computed(() => env.value === ENV_ENUM.PROD);
    watch(() => route.name, updateEnv);
    const handleTabClick = (tab) => {
      tabClicked[tab.paneName] = true;
      route.query.tab = tab.paneName;
    };
    const renderTabContent = (tab) => activeTab.value === tab;
    onBeforeUnmount(() => {
      removeEnvStorage();
    });

    return {
      env,
      baseData,
      activeTab,
      loading,
      tabClicked,
      fetchDetail,
      handleTabClick,
      renderTabContent,
      isProd,
    };
  },
};
</script>
<style lang="scss" scoped>
.env-detail {
  margin: -20px;
  :deep .el-tabs__content {
    background: transparent;
  }
}
</style>
<style lang="scss">
.env-detail__content {
  &__head {
    margin-bottom: 20px;
  }
}
</style>
