
import { getShowBool } from '@/utils/permission-show-module';
import { ENV_ENUM, ENV_TYPE } from '@/views/env/type';
import { computed, defineComponent, onBeforeUnmount, PropType, ref, toRefs, watch } from 'vue';
import { AppType } from '../../type';
import ApplicationCard from '../ApplicationCard.vue';
import { useSaList } from '@/shared/hooks/list';
import { getApps, getAppsMain } from '@/api/app';
export default defineComponent({
  name: 'AppList',
  props: {
    appType: {
      type: String as PropType<AppType>,
      default: 'main',
    },
    keyword: {
      type: String as PropType<string>,
      default: '',
    },
    create: {
      type: Function as PropType<() => void>,
    },
    env: {
      type: String as PropType<ENV_TYPE>,
      default: ENV_ENUM.DEV,
    },
    envId: {
      type: [String, Number],
      default: null,
    },
    envName: {
      type: String,
      default: '',
    },
  },
  components: {
    ApplicationCard,
  },
  setup(props) {
    const { appType } = toRefs(props);
    const timer = ref();
    const { query, total, list, loading, fetchList, handlePageChange, handlePageSizeChange } = useSaList(
      async (query: any, useLoading = true) => {
        clearTimeout(timer.value);
        if (useLoading) {
          loading.value = true;
        }
        try {
          const api = appType.value === 'main' ? getAppsMain : getApps;
          const params: any = { ...query };
          if (props.env && props.env === 'PROD') {
            params.serviceVersionType = 2;
          }
          const {
            data: { rows, count },
          } = await api(params);
          list.value = rows;
          total.value = count;
          timer.value = setTimeout(() => {
            fetchList(false);
          }, 5000);
        } catch (error) {
          console.log(error);
        }
        loading.value = false;
      },
      {
        keyword: props.keyword,
      },
    );
    fetchList();
    const empty = computed(() => list.value.length === 0);

    onBeforeUnmount(() => {
      timer.value && clearTimeout(timer.value);
    });

    const dynamicLabel = ref('');
    const setDynamicLabel = () => {
      switch (props.env) {
        case ENV_ENUM.TEST:
          dynamicLabel.value = '引入';
          break;
        case ENV_ENUM.PROD:
          dynamicLabel.value = '前往申请';
          break;
        default:
          dynamicLabel.value = '新建';
      }
    };
    setDynamicLabel();
    watch(
      () => props.keyword,
      () => {
        query.keyword = props.keyword;
        fetchList();
      },
    );
    watch(
      () => props.env,
      () => {
        setDynamicLabel();
      },
    );

    return {
      query,
      total,
      list,
      loading,
      empty,
      fetchList,
      handlePageChange,
      handlePageSizeChange,
      getShowBool,
      refresh: fetchList,
      ENV_ENUM,
      dynamicLabel,
    };
  },
});
