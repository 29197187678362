<template>
  <el-form :model="form" ref="formRef">
    <!-- <el-form-item prop="authType" :label-width="labelWidth">
      <template v-slot:label>鉴权方式<i class="el-icon-question info-icon form-item__tooltip_icon"></i></template>
      <el-radio v-model="form.authType" :label="0">默认</el-radio>
      <el-radio v-model="form.authType" :label="1">无鉴权</el-radio>
    </el-form-item> -->
    <el-form-item prop="source" :label-width="labelWidth">
      <template v-slot:label>数据源<i class="el-icon-question info-icon form-item__tooltip_icon"></i></template>
      <el-radio v-model="form.source" :label="0">默认</el-radio>
      <el-radio v-model="form.source" :label="1">自定义</el-radio>
    </el-form-item>

    <template v-if="form.source === 1">
      <el-form-item
        class="config-item"
        label="数据库URL"
        :label-width="labelWidth"
        prop="url"
        :rules="[{ required: true, message: '请输入数据库URL', trigger: 'blur' }]"
      >
        <el-input
          v-model.trim="form.url"
          placeholder="jdbc:mysql://<host>:<port>/<database_name>?property1=value1&property2=value2"
        >
        </el-input>
        <span class="config-item__icon" v-html="suffixIcon"></span>
      </el-form-item>
      <el-form-item
        label="数据库账号"
        class="config-item"
        :label-width="labelWidth"
        prop="username"
        :rules="[{ required: true, message: '请输入数据库账号', trigger: 'blur' }]"
      >
        <el-input v-model.trim="form.username"> </el-input>
        <span class="config-item__icon" v-html="suffixIcon"></span>
      </el-form-item>
      <el-form-item
        class="config-item"
        label="数据库密码"
        :label-width="labelWidth"
        prop="password"
        :rules="[{ required: true, message: '请输入数据库密码', trigger: 'blur' }]"
      >
        <el-input v-model.trim="form.password" type="password" show-password> </el-input>
        <span class="config-item__icon" v-html="suffixIcon"></span>
      </el-form-item>
      <el-form-item style="text-align: right">
        <el-button type="text" @click="handleTest" :disabled="testing">{{ testing ? '校验中...' : '校验' }}</el-button>
      </el-form-item>
    </template>

    <el-form-item class="form-btns">
      <el-button type="primary" @click="handlePrevStep" :disabled="submitLoading">上一步</el-button>
      <el-button type="primary" @click="onSubmit" :loading="submitLoading">完成</el-button>
      <el-button @click="handleCancel" :disabled="submitLoading">取消</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
/* eslint-disable */
import { addProject, cosInit } from '@/api/project';
import { testProjectCustomDbConfig } from '@/api/project/project';
// import { ElMessageBox } from 'element-plus';
import { ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent, reactive, ref, computed } from 'vue';
import { ENV_ENUM } from '../../type';
const IconSuccess =
  '<path d="M512 1024C229.2352 1024 0 794.7648 0 512S229.2352 0 512 0s512 229.2352 512 512-229.2352 512-512 512z m271.5264-653.7088l-54.2976-54.3104-307.7376 307.7376-126.72-126.72-54.2976 54.3104L385.28 696.128l36.1984 36.1984z" fill="#23AD3E"></path>';
const IconError =
  '<path d="M512 0A512 512 0 1 0 1024 512 512 512 0 0 0 512 0z m209.204301 669.673978a36.555699 36.555699 0 0 1-51.750538 51.640431L511.779785 563.64043 353.995699 719.662796a36.555699 36.555699 0 1 1-52.301075-51.089893 3.303226 3.303226 0 0 1 0.88086-0.88086L460.249462 511.779785l-157.013333-157.453763a36.665806 36.665806 0 1 1 48.777634-55.053764 37.876989 37.876989 0 0 1 2.972904 2.972903l157.233548 158.114409 157.784086-156.132473a36.555699 36.555699 0 0 1 51.420215 52.08086L563.750538 512.220215l157.013333 157.453763z" fill="#FF5252"></path>';
export default defineComponent({
  props: {
    baseForm: {
      type: Object,
      default () {
        return {};
      },
    },
    env: {
      type: String,
      default: ENV_ENUM.TEST,
    },
  },
  emits: ['complete', 'success'],
  setup (props, { emit }) {
    const form = reactive({ ...props.baseForm.dbConfig });
    const formRef = ref(null);
    const testing = ref(false);
    const submitLoading = ref(false);
    const testResult = ref(null);
    const onSubmit = () => {
      formRef.value.validate(async (valid) => {
        if (!valid) {
          return;
        }
        emit('complete', {
          currentStep: 2,
          nextStep: 2,
          form,
        });
        const saveData = {
          ...props.baseForm.base,
          envFlag: props.env,
        };
        if (form.source === 1) {
          saveData.dbConfig = {
            ...form,
          };
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await testSqlLinks();
          if (!testResult.value) {
            return;
          }
        }
        saveData.owner = Array.isArray(saveData.owner) ? (saveData.owner || []).join(',') : saveData.owner
        saveData.authType = form.authType || 0;
        submitLoading.value = true;
        try {
          const { code, data } = await addProject(saveData);

          if (!code && data) {
            cosInit(data.id);
          }
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleCancel();

          ElMessageBox.alert(
            '权限用户可在"环境详情中"查看环境信息和默认数据源地址、账号、密码',
            '新环境和环境数据源已创建',
            {
              confirmButtonText: '我知道了',
              callback: () => {
                ElMessage.success({
                  duration: 1000,
                  message: '创建成功',
                  onClose () {
                    emit('success', data)
                  },
                });
              },
            },
          );
        } catch (e) {
        } finally {
          submitLoading.value = false;
        }
      });
    };

    const testSqlLinks = async () => {
      testing.value = true;
      testResult.value = null;
      try {
        const { data } = await testProjectCustomDbConfig(form);
        testResult.value = data.useable;
      } catch (e) {
      } finally {
        testing.value = false;
      }
    };

    /**
     * 校验
     */
    const handleTest = () => {
      formRef.value.validate(async (valid) => {
        if (!valid) {
          return;
        }
        await testSqlLinks();
      });
    };
    const handleCancel = () => {
      emit('cancel', {
        currentStep: 2,
      });
      formRef.value.resetFields();
    };
    const handlePrevStep = () => {
      emit('change-step', 1);
    };

    const suffixIcon = computed(() => {
      switch (testResult.value) {
        // todo 不知道为啥返回svg内容不行
        case true:
          return `<svg width="1024" height="1024" viewBox="0 0 1024 1024">${IconSuccess}</svg>`;
        case false:
          return `<svg width="1024" height="1024" viewBox="0 0 1024 1024">${IconError}</svg>`;
        default:
          return '';
      }
    });

    return {
      form,
      formRef,
      labelWidth: '92px',
      onSubmit,
      handleCancel,
      handlePrevStep,
      handleTest,
      testing,
      suffixIcon,
      testResult,
      submitLoading,
    };
  },
});
</script>
<style lang="scss" scoped>
.config-item {
  :deep(.el-form-item__content) {
    display: flex;
    align-items: center;
  }
  &__icon {
    width: 16px;
    height: 16px;
    display: inline-flex;
    flex-shrink: 0;
    margin-left: 4px;
  }
  :deep() {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
.info-icon {
  cursor: pointer;
  padding-right: 20px;
  &:hover {
    &::after {
      content: "项目创建后，权限用户可在项目详情中查看数据源";
      position: absolute;
      margin-top: 30px;
      margin-left: -40px;
      z-index: 11;
      padding: 5px;
      background-color: white;
      box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    }
  }
}
</style>
