<template>
  <div class="env-detail__content">
    <el-row :gutter="20">
      <el-col :xs="24" :md="16">
        <div class="env-detail__panel">
          <div class="env-detail__panel__title">
            <span>基本信息</span>
            <a @click="handleEdit" v-if="getShowBool('updateEnv')">编辑</a>
          </div>
          <div class="env-detail__panel__body">
            <sa-descriptions :column="2">
              <sa-descriptions-item label="英文名">{{ env === 'PROD' ? 'prod' : envData.name }}</sa-descriptions-item>
              <sa-descriptions-item label="中文名">{{ envData.description }}</sa-descriptions-item>
              <sa-descriptions-item label="负责人">{{
                (envData.ownerUsers || []).map((item) => item.displayName).join(',')
              }}</sa-descriptions-item>
              <sa-descriptions-item label="创建时间">{{ dateFormat(envData.createTime) }}</sa-descriptions-item>
              <sa-descriptions-item label="描述">{{ envData.remark }}</sa-descriptions-item>
            </sa-descriptions>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :md="8">
        <div class="env-detail__panel">
          <div class="env-detail__panel__title">
            <span>数据源</span>
            <a @click="handleToggleDbConfigView" v-if="getShowBool('selectDatasource')">{{
              isViewDbConfig ? '取消' : '查看'
            }}</a>
          </div>
          <div class="env-detail__panel__body">
            <sa-descriptions :column="1">
              <sa-descriptions-item label="数据库URL">{{ getDbConfigItem('jdbcUrl') }}</sa-descriptions-item>
              <sa-descriptions-item label="数据库账号">{{ getDbConfigItem('username') }}</sa-descriptions-item>
              <sa-descriptions-item label="数据库密码">{{ getDbConfigItem('password') }}</sa-descriptions-item>
            </sa-descriptions>
          </div>
        </div>
      </el-col>
    </el-row>
    <create-form-dialog ref="createDialogRef" :env="env" edit @success="handleUpdateSuccess" />
  </div>
</template>
<script>
import { ref } from 'vue';
import dateFormat from '@/utils/date-format';
import CreateFormDialog from '../list/create/Index.vue';
import { ENV_ENUM } from '../type';
import { getShowBool } from '@/utils/permission-show-module';

export default {
  name: 'EnvDetailBase',
  props: {
    env: {
      type: String,
      default: ENV_ENUM.TEST,
    },
    envData: {
      type: Object,
      default: () => ({}),
    },
    fetchDetail: {
      type: Function,
      default: () => () => {
        //
      },
    },
  },
  components: {
    CreateFormDialog,
  },
  setup(props) {
    const isViewDbConfig = ref(false);
    const createDialogRef = ref();

    const getDbConfigItem = (key) => {
      if (isViewDbConfig.value) {
        return props.envData?.dbConfig[key];
      }
      return '******';
    };
    const handleToggleDbConfigView = () => {
      isViewDbConfig.value = !isViewDbConfig.value;
    };
    const handleUpdateSuccess = () => {
      props.fetchDetail();
      createDialogRef.value.handleCancel();
    };
    const handleEdit = () => {
      createDialogRef.value.openDialog(props.envData);
    };
    return {
      isViewDbConfig,
      createDialogRef,
      handleUpdateSuccess,
      dateFormat,
      getDbConfigItem,
      handleToggleDbConfigView,
      handleEdit,
      getShowBool,
    };
  },
};
</script>
<style lang="scss" scoped>
.env-detail__content {
  .env-detail__panel {
    background-color: white;
    padding: 20px;
    height: 100%;
    min-height: 260px;
    &__title {
      font-weight: bolder;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      a {
        font-weight: normal;
      }
    }
    &__body {
      margin-top: 20px;
    }
  }
}
</style>
