import { ref } from 'vue';
import { STATUS } from '@/views/demands/publish/constant';

// 双向绑定选中的值
const selectedResult = ref();

// select onChange事件
const handleResultChange = (val: Array<string>) => {
  selectedResult.value = val;
};

// 申请人下拉列表
const reviewResultList = ref();

// 获取审核结果列表
const fetchList = () => {
  reviewResultList.value = Object.entries(STATUS).map((item) => ({
    id: item[0],
    name: item[1],
  }));
};

const getResultByCode = (code: string) => STATUS[code];

export const useReviewResult = (initList = false) => {
  if (initList) {
    fetchList();
  }
  return {
    selectedResult,
    handleResultChange,
    reviewResultList,
    getResultByCode,
  };
};
